import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const Terms = () => {
  return (
    <main className="privacy">
      <NavBar />

      <section className="privacy__content">
        <h1>Правила пользования обменным сервисом Cryptoroyal.Exchange</h1>
        <p className="m-0">Общие положения</p>
        <p className="pl-20">
          Данное соглашение устанавливает порядок оказания сервисом
          Cryptoroyal.Exchange услуг по обмену цифровых активов.
        </p>
        <p className="pl-20">
          Сервис Cryptoroyal.Exchange означает сайт
          https://cryptoroyal.exchange/ (далее – «Веб-сайт»).
        </p>
        <p className="pl-20">
          К настоящим Условиям применяется следующая терминология:
        </p>
        <ul className="pl-60">
          <li>
            «Пользователь», «Клиент», «Вы» и «Ваш» относятся к вам, физическому
            лицу, получающему доступ к Веб-сайту, использующему наши услуги
            через Веб-сайт и принимающему настоящие Условия.
          </li>
          <li>
            «Cryptoroyal.Exchange», «Услуги», «Мы» и «Нас» относятся к сервису
            Cryptoroyal.Exchange.
          </li>
          <li>
            «Сторона» относится либо к вам, либо к нам. Во избежание каких-либо
            сомнений, договаривающимися сторонами по настоящим Условиям являются
            Вы и сервис Cryptoroyal.Exchange.
          </li>
          <li>
            «Цифровой актив», «Криптовалюта» относятся к записям данных реестра
            программного обеспечения на основе блокчейна.
          </li>
          <li>
            «Обмен» означает обмен криптоактива на другой или тот же криптоактив
            или конвертацию бумажной валюты в криптовалюту и наоборот.
          </li>
          <li>
            «Криптовалютные активы» означают тип активов, которые могут
            передаваться только и исключительно с использованием технологии
            блокчейн, включая, помимо прочего, цифровые монеты и цифровые
            токены, а также любые другие цифровые средства обмена.
          </li>
          <li>
            «Обмен с плавающим курсом» означает обмен криптовалюты по
            классическому курсу, осуществляемый по текущему наиболее выгодному
            курсу на рынке, при котором Сервис не гарантирует этот курс.
          </li>
          <li>
            «Обмен с фиксированным курсом» означает обмен, осуществляемый по
            тому же курсу, который отображается клиенту в начале обмена,
            независимо от последующих колебаний курса.
          </li>
        </ul>
        <p className="pl-20">
          Стороны дают согласие на то, что данное Соглашение обеспечивает
          регуляцию взаимоотношений между ними в вопросах, которые связаны с
          обменом электронных валют. Юридическая сила этого электронного
          Соглашения не уступает письменному документу.
        </p>
        <p className="pl-20">
          Соглашение выступает в качестве публичной оферты. Ее акцепт
          совершается через подачу Клиентом заявки на проведение обмена через
          обменный сервис Cryptoroyal.Exchange.
        </p>
        <p className="m-0">Предмет Соглашения</p>
        <p className="pl-20">
          Обменным сервисом Cryptoroyal.Exchange предоставляются услуги,
          описанные в пункте №4 этого Соглашения, а Клиент соблюдает стандарты,
          описанные в пункте №9 Соглашения. Порядок оказания услуг
          устанавливается внутренним регламентом обменного сервиса. Положения
          регламента устанавливаются в пункте №5 соглашения.
        </p>
        <p className="pl-20">
          Клиент должен оплатить услуги обменного сервиса в соответствии с
          условиями, которые оговорены соглашением.
        </p>
        <p className="m-0">Взаимные обязательства Сторон</p>
        <p className="pl-20">Обменный сервис Cryptoroyal.Exchange обязан:</p>
        <ul className="pl-60">
          <li>
            Совершать обмен электронных денег, цифровых активов на основании
            стандартов, предусмотренных Соглашением.
          </li>
          <li>
            Обеспечивать Клиенту информационную и техническую поддержку в ходе
            выполнения заявки, используя возможности онлайн-ресурса
            Cryptoroyal.Exchange.
          </li>
          <li>
            Гарантировать защиту сведений, касательно выполненных транзакций, в
            том числе, личные данные Клиента, время выполнения денежного
            перевода и их размер. Эти сведения доступны Клиенту, который
            совершил транзакции. Если операция выполнялась через анонимную
            систему платежей, то сведения о ней попадают в разряд
            конфиденциальных и тех, которые не подлежат разглашению.
          </li>
          <li>
            Не допускать передачи информации, про совершенные операции третьим
            лицам. Под это правило не подпадают такие случаи: если суд,
            юрисдикция которого распространяется на место расположения обменного
            сервиса, принял соответствующее решение, вступившее в законную силу;
            при получении официального запроса от правоохранительных и налоговых
            структур, которые функционируют в месте нахождения обменного
            сервиса; если есть запрос от администрации платежной системы –
            партнера обменного сервиса.
          </li>
          <li>
            Проведение операций с учетом персональной скидки клиента обменного
            сервиса Cryptoroyal.Exchange.
          </li>
          <li>
            Перечисление денежных средств Клиенту, либо третьему лицу не позднее
            суток после предъявления претензии относительно нарушений, которые
            связаны с несоблюдением стандартов описанных в таких пунктах как
            3.2.5., 5.4., 5.5. или 5.6. действующего Соглашения.
          </li>
        </ul>
        <p className="m-0">Обязанности Клиента:</p>
        <p className="pl-20">
          Предоставлять корректную информацию про реквизиты и личные данные для
          выполнения транзакции.
        </p>
        <p className="pl-20">
          Обозначение актуального адреса электронной почты в глобальной сети.
        </p>
        <p className="pl-20">
          Обеспечивать получение сообщений от Cryptoroyal.Exchange. Решить
          проблему доступа к глобальной сети при помощи компьютерного
          оборудования или какого-либо другого устройства. Обеспечивать
          безопасное соединение с обменным сервисом при помощи надежного
          антивирусного программного обеспечения.
        </p>
        <p className="pl-20">Выполнять положения Соглашения.</p>
        <p className="pl-20">
          Уведомлять представителей и техническую службу обменного сервиса
          Cryptoroyal.Exchange о возникновении ситуаций, когда денежный перевод
          не был выполнен в полном объеме, либо частично, а также о случаях,
          оговоренных в пунктах 5.4. – 5.6. действующего Соглашения. Уведомление
          должно быть отправлено на протяжении месяца после совершения
          транзакции. В противном случае, спорные платежи могут поступить в
          распоряжение обменного сервиса Cryptoroyal.Exchange.
        </p>
        <p className="pl-20">
          Выполнять требования нормативных актов, которые регулируют порядок
          выполнения обмена.
        </p>
        <p className="pl-20">
          Воздерживаться от использования систем для неправомерного увеличения
          трафика.
        </p>
        <p className="m-0">Обменный сервис Cryptoroyal.Exchange имеет право:</p>
        <p className="pl-20">
          Приостанавливать работу на время выполнения работ по модернизации
          сайта или для исправления ошибок или неполадок, возникающих в работе
          обменного сервиса.
        </p>
        <p className="pl-20">
          Остановить процесс осуществления денежного перевода до выяснения
          обстоятельств, если была подана жалоба относительно мошеннических
          действий или имеется официальный запрос от компетентных органов.
        </p>
        <p className="pl-20">Формирование системы скидок.</p>
        <p className="pl-20">
          Определение размера комиссионных за совершение обмена в конкретном
          направлении.
        </p>
        <p className="pl-20">
          Отказ в обслуживании Клиенту без объяснения мотивации.
        </p>
        <p className="pl-20">
          Если транзакция не была выполнена по причине ошибки, то обменный
          сервис вправе запросить от Клиента дополнительные сведения, к примеру,
          скриншот электронного кошелька, чек об оплате через почту.
        </p>
        <p className="pl-20">
          Прекратить беседу с Клиентом, некорректно ведущим себя с сотрудником
          обменного сервиса Cryptoroyal.Exchange, а также задающего вопросы, не
          касающиеся работы обменного сервиса или отказа в предоставлении
          необходимых сведений.
        </p>
        <p className="pl-20">
          Блокировка денежного перевода при наступлении обстоятельств, которые
          описаны в пунктах 5.4. – 5.6. Соглашения.
        </p>
        <p className="pl-20">
          При необходимости, выполнять блокировку операции и заморозку денежных
          средств на счете пользователя до идентификации его личности и
          установления истинности информации, которая была указана клиентом в
          системе.
        </p>
        <p className="pl-20">
          В случае неосновательного обогащения Клиентом электронными денежными
          средствами обменного сервиса Cryptoroyal.Exchange, либо сторонних
          сервисов, администрация сайта вправе приостановить выполнение заявки
          до выяснения причин и всех обстоятельств, а также возмещения убытков,
          понесенных обменным сервисом Cryptoroyal.Exchange или другими
          обменными сервисами.
        </p>
        <p className="pl-20">
          Администрация обменного сервиса Cryptoroyal.Exchange имеет право
          пересматривать условия реферальной программы.
        </p>
        <p className="m-0">Перечень услуг</p>
        <p className="pl-20">
          Обменный сервис Cryptoroyal.Exchange осуществляет обмен электронных
          валют различных платежных систем и других разновидностей титульных
          знаков, используя услуги банковских структур. С перечнем партнеров
          можно ознакомиться на информационном ресурсе обменного сервиса
          Cryptoroyal.Exchange.
        </p>
        <p className="pl-20">
          Обменный сервис не проверяет законность происхождения денег,
          используемых для осуществления обменных операций.
        </p>
        <p className="m-0">Регламент обменных операций</p>
        <p className="pl-20">
          Сотрудники обменного сервиса Cryptoroyal.Exchange выполняют перевод
          средств только по факту получения от клиента денег для совершения
          платежа.
        </p>
        <p className="pl-20">
          Среднее время обработки заявки составляет 2 часа после подтверждения
          оператором факта получения средств. Гарантированное время выполнения
          заявки - 24 часа.
        </p>
        <p className="pl-20">Цены, курсы валют и подтверждения</p>
        <ul className="pl-60">
          <li>
            Обменный сервис Cryptoroyal.Exchange пытается предоставить точную
            информацию о ценах и обменных курсах, но эта информация очень
            нестабильна и может быстро меняться без ведома Пользователей об этих
            изменениях.
          </li>
          <li>
            Обменный сервис Cryptoroyal.Exchange вправе пересчитать курс,
            который зафиксирован по заявке, в случае: длительной задержки
            поступления денег от клиента к обменному сервису, по причине
            задержки со стороны стороннего сервиса; клиент указал минимальную
            комиссию при переводе за транзакцию, что привело к длительной
            задержке поступления средств на счет обменного сервиса
            Cryptoroyal.Exchange.
          </li>
          <li>
            Для заявок с плавающим обменным курсом полученный вами обменный курс
            рассчитывается на момент принятия вашего платежа. Из-за различий в
            безопасности между блокчейнами ваш платеж обычно считается
            «принятым» как минимум при двух (2) подтверждениях сети
            соответствующей криптовалютной транзакции. Важно отметить, что
            платеж, транслируемый в сеть блокчейна, не означает принятия
            Cryptoroyal.Exchange этого платежа.
          </li>
          <li>
            Обратите внимание, что для технологии блокчейн сетевые сборы могут
            быть изменены. Если вы внесете депозит более чем через 60 минут
            после создания вашей биржи или отправите сумму депозита, отличную от
            указанной при создании биржи, мы не можем гарантировать, что сетевые
            комиссии не повлияют на сумму криптовалюты, которую вы получите.
          </li>
          <li>
            Имейте в виду, что могут быть случаи, когда обменный курс,
            представленный на нашей платформе, отличается от курсов,
            предлагаемых другими услугами или обменами, предоставляемыми другими
            компаниями. Эти различия могут возникнуть из-за динамичного
            характера рынка криптовалют, а также из-за того, что ставки у наших
            поставщиков ликвидности могут значительно отличаться от источников.
            Важно отметить, что Cryptoroyal.Exchange не несет ответственности за
            такие различия в курсах по сравнению с внешними источниками.
            Обратите внимание, что Cryptoroyal.Exchange не несет ответственности
            за курсы, отображаемые на внешних ресурсах. Мы советуем всем
            Пользователям проверять фактические курсы, отображаемые Нами, прежде
            чем начинать обмен через нашу платформу. Хотя мы стремимся
            предоставлять точную и своевременную информацию, мы не можем брать
            на себя ответственность за расхождения в ставках с внешними
            ресурсами. Пользователям рекомендуется проявлять осмотрительность и
            учитывать присущую рынку цифровых активов волатильность при принятии
            решений об обмене.
          </li>
        </ul>
        <p className="pl-20">
          Обмен считается завершенным после перечисления денежных средств на
          предоставленные Клиентом сервиса реквизиты.
        </p>
        <p className="pl-20">
          Клиент не имеет права отменить начатый перевод и не может возвратить
          деньги, которые уже были отправлены.
        </p>
        <p className="pl-20">
          Если клиентом был выполнен платеж, размер которого не совпадает с
          суммой, обозначенной в заявке, администрация обменного сервиса
          Cryptoroyal.Exchange имеет право приостановить транзакцию. Операция
          будет возобновлена после обращения Клиента на основании пункта 3.2.5
          Соглашения. При этом обменный сервис Cryptoroyal.Exchange имеет право
          пересчитать сумму в заявке, если он существенно изменился.
        </p>
        <p className="pl-20">
          При установлении неверных реквизитов, обменный сервис
          Cryptoroyal.Exchange приостанавливает выполнение заявки. После
          обращения клиента в поддержку сервиса с указанной в заявке электронной
          почты, обменный сервис Cryptoroyal.Exchange может изменить указанные
          реквизиты или сделать возврат суммы с удержанием комиссии той или иной
          платежной системы.
        </p>
        <p className="pl-20">
          Если оплата счета прошла с постороннего аккаунта или же были внесены
          изменения в примечания к платежу, то обменным сервисом
          Cryptoroyal.Exchange будет осуществлена блокировка транзакции. Возврат
          денег может быть выполнен по факту обращения Клиентом на основании
          пункта 3.2.5. за вычетом комиссии платежной системы и возможного
          штрафа.
        </p>
        <p className="pl-20">
          Если обменным сервисом Cryptoroyal.Exchange выполняется обмен на
          криптовалюту биткоин, то выполнение транзакции пользователю в сети
          биткоин зависит от ее загруженности.
        </p>
        <p className="m-0">Гарантии и ответственность сторон</p>
        <p className="pl-20">
          Обменный сервис Cryptoroyal.Exchange не отвечает за последствия
          некорректной эксплуатации ресурса и за ошибки, которые были допущены
          Клиентом при оформлении заявки. Операция не может быть отменена, а
          денежные средства возвращены, даже если Клиентом были указаны неверные
          платежные реквизиты.
        </p>
        <p className="pl-20">
          Обменный сервис Cryptoroyal.Exchange не отвечает за ущерб от
          невозможности эксплуатации Клиентом оборудования в целом или его
          составляющих.
        </p>
        <p className="pl-20">
          Обменный сервис Cryptoroyal.Exchange не отвечает за задержку платежей
          по причине действий финансовых учреждений или электронных систем
          платежей.
        </p>
        <p className="pl-20">
          Компания Cryptoroyal.Exchange не отвечает за убытки, либо неполучение
          дохода, если они возникли в результате ошибочных представлений
          Клиентом, касательно тарифов, либо прибыльности транзакций.
        </p>
        <p className="pl-20">
          Обменный сервис не отвечает за финансовые потери, возникшие из-за
          задержек перевода.
        </p>
        <p className="pl-20">
          Клиент подтверждает, что у него есть законные основания для
          распоряжения деньгами, используемыми для совершения транзакций.
        </p>
        <p className="pl-20">
          Клиент соглашается на возмещение ущерба третьи лицам, если причиной
          его возникновения стала эксплуатация ресурса.
        </p>
        <p className="m-0">Изменений условий соглашения</p>
        <p className="pl-20">
          Администрация обменного сервиса Cryptoroyal.Exchange вправе
          редактировать данное Соглашение в любое время. Изменения приобретают
          силу после их опубликования на официальном сайте Cryptoroyal.Exchange.
        </p>
        <p className="m-0">Форс-мажор</p>
        <p className="pl-20">
          Стороны не несут ответственность за невыполнение или ненадежное
          выполнение условий соглашения, если причиной этого стали непреодолимые
          обстоятельства. К ним причисляют войну, стихийные бедствия, пожар,
          бунт, массовые беспорядки, теракт, решения властей. Кроме того, к ним
          относят сбои в работе электрической сети, отсутствие доступа к сети
          интернет или прочим системам.
        </p>
        <p className="m-0">Условия для выполнения обмена</p>
        <p className="pl-20">
          Администрация запрещает использовать обменный сервис
          Cryptoroyal.Exchange с целью осуществления мошеннических, либо других
          незаконных операций.
        </p>
        <p className="pl-20">
          Обменный сервис Cryptoroyal.Exchange может передавать сведения про
          платежи, незаконность которых была установлена правоохранительными
          структурами, администрации системы платежей, а также потерпевшему, по
          его требованию.
        </p>
        <p className="pl-20">
          Обмен средств происходит только по факту вывода Клиентом денег с
          принадлежащего ему виртуального кошелька. При этом он несет
          ответственность за законность источников их поступления.
        </p>
        <p className="pl-20">
          Банковский перевод производится посредством сервиса интернет-банкинга
          выбранной клиентом системы платежей. Если заявка была создана при
          помощи оператора банка или же с использованием банкомата, то денежные
          средства подлежат возврату не позднее суток.
        </p>
        <p className="pl-20">
          Обменный сервис Cryptoroyal.Exchange не несет ответственность за
          переводы, которые были совершены для клиента третьими лицами.
        </p>
        <p className="pl-20">
          Если клиент нажал на кнопку соответствующую соглашению с правилами
          осуществления обмена, то он безоговорочно принимает условия этого
          соглашения.
        </p>
        <p className="pl-20">
          Использование обменного сервиса Cryptoroyal.Exchange для создания
          многочисленных заявок, с целью извлечения выгоды на разнице курсов
          обмена – категорически запрещено.
        </p>
        <p className="m-0">Процедура AML/KYC</p>
        <p className="pl-20">
          Пользователям не нужно создавать учетные записи: Вам не нужно
          регистрироваться или входить в систему, чтобы использовать Веб-сайт.
          Однако Cryptoroyal.Exchange использует автоматизированную систему
          управления рисками для проверки всех транзакций, совершаемых
          Пользователями.
        </p>
        <p className="pl-20">
          Принимая настоящие Условия, вы соглашаетесь пройти процедуру AML/KYC,
          которая может быть применена к вам или может быть запрошена
          Cryptoroyal.Exchange в любое время.
        </p>
        <p className="pl-20">
          Во время процедуры AML/KYC Cryptoroyal.Exchange оставляет за собой
          право запросить дополнительную информацию и документы, включая, помимо
          прочего, скан вашего документа, удостоверяющего личность,
          действительного в вашей стране, и дополнительную информацию о
          происхождении средств.
        </p>
        <p className="pl-20">
          В отдельных особых случаях (например, для подтверждения вашей личности
          с целью прохождения процедуры AML/KYC) мы оставляем за собой право
          заморозить вашу обменную транзакцию на любой период времени,
          необходимый для завершения расследования и процедуры AML/KYC.
        </p>
        <p className="pl-20">
          Если вы отказываетесь пройти процедуру AML/KYC или не предоставляете
          Cryptoroyal.Exchange запрошенные документы и информацию,
          Cryptoroyal.Exchange имеет право отказать в выполнении ваших
          транзакций (текущих и будущих).
        </p>
        <p className="pl-20">
          Принимая настоящие Условия, вы соглашаетесь и гарантируете
          предоставлять правдивую, точную, актуальную и полную информацию о себе
          и берете на себя полную ответственность за ее точность, полноту и
          достоверность.
        </p>
        <p className="pl-20">
          В отдельных особых случаях (если есть подозрение, что транзакция
          связана с незаконной деятельностью) мы оставляем за собой право
          заморозить вашу обменную транзакцию на любой период времени,
          необходимый для завершения расследования и процедуры AML/KYC.
        </p>
        <p className="pl-20">
          Для получения дополнительной информации прочтите официальную политику
          по борьбе с отмыванием денег и финансированием терроризма
          Cryptoroyal.Exchange <a href="/aml-policy">здесь</a>.
        </p>
      </section>

      <Footer />
    </main>
  );
};

export default Terms;
