import React from "react";
import Banner from "../components/Home/Banner";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

function Home() {
  return (
    <main>
      <NavBar />
      <Banner />
      <Footer />
    </main>
  );
}

export default Home;
