import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from '../pages/Home';
import About from '../pages/About';
import Privacy from '../pages/Privacy';
import AML from '../pages/AML';
import Terms from '../pages/Terms';
import Currencies from '../pages/Currencies';

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/aml-policy" element={<AML />} />
        <Route path="/terms-of-use" element={<Terms />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/supported-currencies" element={<Currencies />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
