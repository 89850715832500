import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const AML = () => {
  return (
    <main className="privacy">
      <NavBar />

      <section className="privacy__content">
        <span className="date">Последнее обновление «22» декабря 2022 г.</span>
        <h1>
          ПОЛИТИКА ПРЕДОТВРАЩЕНИЯ И ВЫЯВЛЕНИЯ ОТМЫВАНИЯ ДЕНЕГ И ФИНАНСИРОВАНИЯ
          ТЕРРОРИЗМА
        </h1>
        <h4>1. ОСНОВНАЯ ИНФОРМАЦИЯ</h4>
        <p>
          Политика по предотвращению и выявлению отмывания денег и
          финансирования терроризма («Политика») была разработана и управляется
          Star Exchange FZC («Компания»). Этот сайт работает под торговой маркой
          AMLCompliance. Девелоперская компания Star Exchange FZC - компания,
          зарегистрированная и существующая в соответствии с нормативно-правовой
          базой Объединенных Арабских Эмиратов с регистрационным номером 7073, с
          юридическим адресом: Al Shmookh Business Center, One UAQ, UAQ Free
          Trade Zone, Umm Al Quwain, ОАЭ
        </p>
        <p>Просим Вас внимательно изучить настоящую Политику.</p>
        <p>
          Цель Политики предоставить партнерам Компании, Клиентам, поставщикам,
          подрядчикам, сотрудникам, регулирующим органам, правоохранительным
          органам и другим заинтересованным сторонам общий обзор элементов и
          процедур режима соблюдения требований ПОД/ФТ Компании. Ни в коем
          случае настоящую Политику нельзя рассматривать как полный набор всех
          политик, процедур и средств контроля, применяемых Компанией для
          предотвращения отмывания денег, финансирования терроризма и других
          форм незаконной деятельности.
        </p>
        <h4>2. ОЦЕНКА РИСКОВ ДЛЯ ПРЕДПРИЯТИЯ</h4>
        <p>
          Оценка рисков в масштабах всего предприятия предназначена для
          выявления областей, в которых существует неотъемлемый риск ОД/ФТ в
          характере бизнеса и операций Компании.
        </p>
        <h4>2.1 Риск-ориентированный подход и оценка клиентских рисков</h4>
        <p>
          Компания должна применять риск-чувствительный подход к определению
          степени и характера:
        </p>
        <ul>
          <li>
            меры Надлежащей Проверки Клиента («НПК»), которые должны применяться
            к Клиенту и любой третьей стороне или бенефициарному владельцу; и
          </li>
          <li>его постоянный мониторинг деловых отношений.</li>
        </ul>
        <p>
          Применяя меры Надлежащей Проверки Клиента и проводя постоянный
          мониторинг, Компания должна:
        </p>
        <ul>
          <li>
            оценить риск того, что любые Деловые Отношения или разовые операции
            связаны или будут связаны с отмыванием денег или финансированием
            терроризма, в зависимости от типа Клиента, Деловых Отношений,
            продукта или транзакции, и рассмотреть, с учетом риска,
            необходимость дополнительной информации об идентификации или
            отношениях;
          </li>
          <li>
            на основании полученной информации о Надлежащей Проверки Клиента и
            информации, полученной выше, подготовить оценку риска в отношении
            Клиента;
          </li>
          <li>
            периодически обновлять информацию о Надлежащей Проверки Клиентов,
            которую он имеет, и соответствующим образом скорректировать оценку
            рисков, которую он сделал.
          </li>
        </ul>
        <p>
          Транзакция является разовой сделкой, если транзакция осуществляется
          иначе, чем в рамках Деловых Отношений, и выполняется как:
        </p>
        <ul>
          <li>разовая транзакция на сумму, указанную ниже, или более; или</li>
          <li>
            две или более связанных транзакций, которые в сумме составляют
            сумму, указанную ниже, или более, где:
            <ul>
              <li>
                с самого начала любому лицу, занимающемуся какой-либо
                транзакцией, кажется, что эти транзакции связаны между собой;
                или
              </li>
              <li>
                на любом более позднем этапе до сведения любого лица,
                занимающегося любой из этих транзакций, доходит информация о
                том, что эти транзакции связаны между собой.
              </li>
            </ul>
          </li>
        </ul>
        <p>Сумма, указанная для вышеуказанных предложений, составляет:</p>
        <ul>
          <li>
            в случае транзакции или связанных транзакций, осуществленных в ходе
            деятельности по оказанию денежных услуг, 2500 долларов США; или
          </li>
          <li>
            в случае любой другой транзакции или связанных транзакций — 10 000
            долларов США.
          </li>
        </ul>
        <p>
          При подготовке оценки рисков в отношении Клиента Компания должна
          учитывать все соответствующие риски и учитывать, в частности,
          актуальность следующих рисков:
        </p>
        <ul>
          <li>клиентский риск;</li>
          <li>продуктовый риск;</li>
          <li>риск доставки; и</li>
          <li>риск страны;</li>
        </ul>
        <p>Компания должна быть в состоянии продемонстрировать:</p>
        <ul>
          <li>
            что степень Надлежащей Проверки Клиента, применяемая в любом случае,
            соответствует обстоятельствам дела, включая риски отмывания денег и
            финансирования терроризма; и
          </li>
          <li>
            что она получила соответствующую информацию для проведения оценки
            риска, требуемой в соответствии с вышеизложенным.
          </li>
        </ul>
        <h4>3. МЕРЫ ПО НАДЛЕЖАЩЕЙ ПРОВЕРКЕ КЛИЕНТА</h4>
        <p>
          Компания предпринимает шаги, чтобы узнать, кто является ее Клиентами.
          Компания не ведет анонимные учетные записи или учетные записи на
          вымышленные имена. Компании не разрешается открывать или поддерживать
          номерные счета. Номерная учетная запись — это учетная запись, которая
          открыта не на имя Клиента и управляется с помощью номера, присвоенного
          основному Клиенту.
        </p>
        <p>
          Попытка Клиента использовать или использование одноразовой почты будет
          рассматриваться как попытка ввести Компанию в заблуждение. В таком
          случае Компания вправе отказать в предоставлении услуг этим Клиентам.
        </p>
        <p>Меры Надлежащей Проверки Клиентов включают:</p>
        <ul>
          <li>
            идентификация Клиента и проверка этой личности с использованием
            надежных, независимых исходных документов, данных или информации;
          </li>
          <li>
            идентификация бенефициарного владельца (владельцев) (Клиента и
            бенефициаров, где это уместно) и принятие разумных мер для проверки
            личности бенефициарного владельца, чтобы Компания была уверена, что
            ей известно, кто является бенефициарным владельцем. Если Клиент
            является юридическим лицом или организацией, Компания предпринимает
            шаги для понимания структуры собственности и контроля Клиента;
          </li>
          <li>
            понимание и, при необходимости, получение информации о цели и
            предполагаемом характере Деловых Отношений;
          </li>
          <li>
            проведение постоянной Надлежащей Проверки в Деловых Отношениях и
            тщательное изучение транзакций, совершенных в ходе этих отношений,
            для обеспечения того, чтобы проводимые транзакции соответствовали
            знаниям Компании о Клиенте, его бизнес-профиле и риске, включая, при
            необходимости, источник средства.
          </li>
        </ul>
        <p>
          Если Компания не может завершить и выполнить требования НПК, как
          указано в Правилах и Кодексе, она не должна открывать счет, начинать
          Деловые отношения или выполнять транзакцию. Если Деловые отношения уже
          установлены, Компания должна прекратить отношения.
        </p>
        <p>
          Независимым источником является проверка сведений, полученных в ходе
          идентификации:
        </p>
        <ul>
          <li>который происходит из двух разных источников;</li>
          <li>
            который был выдан (документы, удостоверяющие личность) или получен
            от третьего лица или места, которое не имеет интереса или связей с
            Клиентом или Компанией, т.е. является нейтральным (например,
            информация, полученная из Интернета, не является такой информацией,
            поскольку она часто исходит от самого Заказчика или его
            достоверность и независимость не могут быть проверены);
          </li>
          <li>
            надежность и независимость которые можно определить без объективных
            препятствий, надежность и независимость также понятны третьему лицу,
            не участвующему в Деловых Отношениях; и
          </li>
          <li>
            включающие данные в которые или полученные через которые, являются
            актуальными, и Компания может получить подтверждение об этом (и в
            некоторых случаях подтверждение также может быть получено на
            основании двух предыдущих пунктов).
          </li>
        </ul>
        <p>
          Информация о Надлежащей Проверке Клиента включает как информацию о
          личности клиента, так и информацию о Деловых Отношениях. Информация о
          взаимоотношениях (т. е. информация о Деловых Отношениях или
          предполагаемых Деловых Отношениях) это информация, необходимая для
          того, чтобы Компания могла полностью понять характер бизнеса Клиента
          или предполагаемый бизнес и обоснование Деловых Отношений. Это будет
          включать информацию об источнике средств Клиента и, в отношениях с
          более высоким риском, об источнике средств Клиента.
        </p>
        <p>
          Характер и объем информации о взаимоотношениях, полученной в отношении
          Клиента, будет зависеть от ряда факторов, таких как страны, с которыми
          он связан, поставляемый продукт или услуга, способ доставки продукта
          или услуги и конкретные факторы применяемые к Клиенту. Основная цель
          состоит в том, чтобы выявить необычную, сложную или более рискованную
          деятельность и операции, которые могут указывать на отмывание денег
          или финансирование терроризма.
        </p>
        <p>
          Кодекс устанавливает информацию об отношениях, которая всегда должна
          быть получена обязанным лицом в отношении юридического лица:
        </p>
        <ul>
          <li>вид юридического лица;</li>
          <li>
            характер деятельности юридического лица и место или места
            осуществления деятельности;
          </li>
          <li>
            его собственность и, если юридическим лицом является компания,
            сведения о любой группе, частью которой является компания, включая
            сведения о собственности группы;
          </li>
          <li>
            подлежит ли юридическое лицо надзору в государстве или за его
            пределами, и если да, то сведения о соответствующем надзорном
            органе.
          </li>
        </ul>
        <p>
          Информация об отношениях, полученная Компанией, должна включать
          информацию, касающуюся:
        </p>
        <ul>
          <li>цель и предполагаемый характер Деловых Отношений;</li>
          <li>вид, объем и стоимость ожидаемой деятельности;</li>
          <li>
            источник средств и, если оценка рисков Клиента указывает на то, что
            Клиент, Деловые отношения или случайная сделка представляют высокий
            риск, источник средств Клиента, третьего лица или бенефициарного
            владельца;
          </li>
          <li>сведения о любых существующих отношениях с Компанией;</li>
          <li>
            если Клиент не является резидентом штата, причина использования
            поставщика услуг, базируется в штате; и
          </li>
          <li>
            такая другая информация, касающаяся отношений, которую Компания
            сочтет уместной с учетом риска.
          </li>
        </ul>
        <p>
          <i>Источник средств и богатства</i>
        </p>
        <p>
          Кодекс предусматривает, что информация об источнике средств всегда
          должна быть получена, а информация об источнике богатства должна быть
          получена в тех случаях, когда клиент, деловые отношения или случайная
          сделка представляют высокий риск.
        </p>
        <p>
          Информация об источнике средств и источнике богатства, а также любая
          транзакция, стоимость которой превышает 10 000,00 долларов США, должна
          быть задокументирована в форме, именуемой «Декларация Источника
          Средств/Богатства».
        </p>
        <h4>
          3.1 ЗСК — Идентификационная Информация и Процедуры Верификации для
          Прямых Персональных Клиентов
        </h4>
        <p>
          Компания предоставляет процедуры «Знай Своего Клиента» (ЗСК) для
          идентификации своих Клиентов. В соответствии с Правилами Компания
          обязана получить идентификацию и подтвердить личность любого лица:
        </p>
        <ul>
          <li>
            который, как Клиент, стремится вступить в Деловые отношения с
            Компанией или совершить случайную сделку, будь то единолично или
            совместно;
          </li>
          <li>который является третьим лицом; или</li>
          <li>
            который является бенефициарным владельцем Клиента или третьего лица.
          </li>
        </ul>
        <p>
          Компания должна получить следующую идентификационную информацию в
          отношении физического лица, которое требуется в соответствии с
          Регуляцией или Законом для идентификации:
        </p>
        <ul>
          <li>
            полное юридическое имя, любые прежние имена и любые другие имена,
            используемые физическим лицом;
          </li>
          <li>гендер физического лица;</li>
          <li>основной адрес проживания физического лица; и</li>
          <li>дата рождения физического лица.</li>
        </ul>
        <p>
          Если Компания определяет, что лицо, которое необходимо
          идентифицировать, представляет более высокий уровень риска, Компания
          должна получить дополнительную информацию в отношении этого лица.
          Дополнительная идентификационная информация, которая должна быть
          получена в отношении лица с более высоким риском, должна включать как
          минимум два из следующих:
        </p>
        <ul>
          <li>место рождения физического лица;</li>
          <li>национальность физического лица;</li>
          <li>
            официальный идентификационный номер, выданный правительством, или
            другой правительственный идентификатор.
          </li>
        </ul>
        <p>
          Если Компания определяет, что физическое лицо, личность которого
          необходимо проверить, представляет низкий уровень риска, Компания
          должна, используя доказательства как минимум из одного независимого
          источника, определить:
        </p>
        <ul>
          <li>
            полное юридическое имя физического лица, любые прежние имена и любые
            другие имена, используемые физическим лицом; и
          </li>
          <li>
            либо:
            <ul>
              <li>основной адрес проживания физического лица или</li>
              <li>дата рождения человека.</li>
            </ul>
          </li>
        </ul>
        <p>
          Если Компания определяет, что физическое лицо, личность которого
          необходимо проверить, представляет более высокий уровень риска,
          Компания должна, используя доказательства как минимум из двух
          независимых источников, определить:
        </p>
        <ul>
          <li>
            полное юридическое имя физического лица, любые прежние имена и любые
            другие имена, используемые физическим лицом;
          </li>
          <li>основной адрес проживания физического лица; и</li>
          <li>
            физического лица:
            <ul>
              <li>дата рождения;</li>
              <li>место рождения;</li>
              <li>национальность; и</li>
              <li>гендер.</li>
            </ul>
          </li>
        </ul>
        <p>
          Если Компания определяет, что физическое лицо, личность которого
          требуется проверить, представляет высокий уровень риска, Компания
          должна, используя доказательства из как минимум двух независимых
          источников наилучших форм идентификации, определить:
        </p>
        <ul>
          <li>национальность или адрес; и</li>
          <li>
            идентификационный номер, выданный правительством, или другой
            государственный идентификатор.
          </li>
        </ul>
        <p>Лучшими формами идентификации личности являются:</p>
        <ul>
          <li>действующий паспорт; </li>
          <li>
            действующее удостоверение личности, выданное национальным
            правительством; и
          </li>
          <li>действующие водительские права.</li>
        </ul>
        <p>
          Заказчик как физическое лицо может предоставить следующие приемлемые
          документы:
        </p>
        <ul>
          <li>
            действующий загранпаспорт с фотографией, удостоверяющей личность;
          </li>
          <li>
            действующее национальное удостоверение личности или документ, но
            только если он предоставляет фотографическое удостоверение личности;
          </li>
          <li>
            действующее водительское удостоверение, но только в том случае, если
            лицензирующий орган проводит проверку личности перед выдачей
            водительского удостоверения, а водительское удостоверение
            предоставляет фотографическое удостоверение личности.
          </li>
        </ul>
        <p>
          Допустимы следующие способы подтверждения адреса проживания
          физического лица:
        </p>
        <ul>
          <li>недавняя выписка из банка или счет за коммунальные услуги;</li>
          <li>
            корреспонденция от центрального или местного правительственного
            департамента или агентства;
          </li>
          <li>
            рекомендательное письмо, подтверждающее адрес проживания от
            регулируемого лица или иностранного регулируемого лица.
          </li>
        </ul>
        <p>
          В тех случаях, когда общие методы идентификации личности
          нецелесообразны, а заинтересованное лицо представляет низкий риск,
          личность этого лица сначала должна быть проверена с использованием:
        </p>
        <ul>
          <li>
            действующее национальное удостоверение личности или документ, но
            только если он предоставляет фотографическое удостоверение личности
            или полные (т.е. не временные) водительские права, выданные на
            Сент-Винсенте и Гренадинах; или
          </li>
          <li>
            свидетельство о рождении вместе с:
            <ul>
              <li>
                недавней выпиской из банка или счет за коммунальные услуги;
              </li>
              <li>документацией, выданной государственным источником; или</li>
              <li>рекомендательным письмом от регулируемого лица.</li>
            </ul>
          </li>
        </ul>
        <h4>
          3.2. Идентификационная Информация и Процедуры Верификации для
          Корпоративных Клиентов
        </h4>
        <p>
          Компания должна получить следующую идентификационную информацию в
          отношении юридического лица, которое требуется в соответствии с
          Регуляцией или Законом идентифицировать:
        </p>
        <ul>
          <li>
            полное название юридического лица и любые торговые наименования,
            которые оно использует;
          </li>
          <li>
            дата учреждения, регистрации или образования юридического лица;
          </li>
          <li>любой официальный идентификационный номер;</li>
          <li>
            юридический адрес или, если он не имеет юридического адреса, адрес
            головного офиса юридического лица;
          </li>
          <li>
            имя и адрес зарегистрированного агента юридического лица (или
            эквивалента), если таковой имеется;
          </li>
          <li>почтовый адрес юридического лица;</li>
          <li>основное место деятельности юридического лица;</li>
          <li>имена директоров юридического лица;</li>
          <li>
            идентификационная информация о тех директорах, которые имеют
            полномочия давать указания Компании относительно Деловых Отношений
            или случайных сделок; и
          </li>
          <li>
            идентификационная информация о физических лицах, являющихся
            конечными владельцами 15% и более акций юридического лица.
          </li>
        </ul>
        <p>
          Если Компания определяет, что юридическое лицо, которое требуется
          идентифицировать, представляет более высокий уровень риска, Компания
          должна получить такую дополнительную идентификационную информацию в
          отношении юридического лица, которую она сочтет целесообразной. В
          таком случае Компания должна получить идентификационную информацию о
          каждом директоре и бенефициарном владельце юридического лица.
        </p>
        <p>
          Если Компания определяет, что юридическое лицо, личность которого
          требуется проверить, представляет низкий риск, Компания должна,
          используя доказательства как минимум из одного независимого источника,
          проверить:
        </p>
        <ul>
          <li>наименование юридического лица;</li>
          <li>официальный идентификационный номер; и</li>
          <li>дата и страна его учреждения, регистрации или образования.</li>
        </ul>
        <p>
          Если Компания определяет, что юридическое лицо, личность которого
          требуется проверить, представляет более высокий уровень риска,
          Компания проверяет:
        </p>
        <ul>
          <li>
            адрес зарегистрированного офиса или головного офиса юридического
            лица, в зависимости от обстоятельств; и
          </li>
          <li>
            адрес основного места деятельности юридического лица, если он
            отличается от его зарегистрированного офиса или головного офиса.
          </li>
        </ul>
        <p>
          Если Компания определяет, что юридическое лицо, личность которого
          требуется проверить, представляет высокий уровень риска, Компания
          должна проверить такие другие компоненты идентификации юридического
          лица, которые она сочтет целесообразными.
        </p>
        <p>
          Клиент как юридическое лицо может предоставить следующие приемлемые
          документы:
        </p>
        <ul>
          <li>свидетельство о регистрации, регистрации или эквивалент;</li>
          <li>справка о хорошем состоянии от Регулятора юридического лица;</li>
          <li>партнерское соглашение;</li>
          <li>меморандум и устав или эквивалентные учредительные документы;</li>
          <li>
            поиск в реестре компаний, включая подтверждение того, что
            юридическое лицо не находится в процессе роспуска, вычеркивания,
            ликвидации или прекращения деятельности;
          </li>
          <li>
            последняя проверенная финансовая отчетность юридического лица; и
          </li>
          <li>
            независимые источники данных, включая электронные источники, т.е.
            услуги бизнес-информации.
          </li>
        </ul>
        <p>
          Если Компания определяет, что юридическое лицо представляет низкий
          уровень риска, следует использовать как минимум один из указанных выше
          методов.
        </p>
        <p>
          Если установлено, что юридическое лицо представляет более высокий
          уровень риска, следует использовать как минимум два из указанных выше
          методов.
        </p>
        <p>
          В случае юридического лица, которое является регулируемым лицом,
          личность директора может быть проверена, если полное имя директора
          получено вместе с письменным подтверждением регулируемого лица о том,
          что заинтересованное лицо является директором.
        </p>
        <h4>3.3. Существующие Клиенты</h4>
        <p>
          Компания обязана применять меры НПК к существующим Клиентам на основе
          существенности и риска, а также проводить надлежащую проверку таких
          существующих отношений в соответствующее время, принимая во внимание,
          предпринимались ли ранее меры НПК, и адекватность полученных данных.
        </p>
        <p>
          Если у Компании возникают подозрения в ОД/ФТ или в любой момент
          становится известно, что ей не хватает достаточной информации о
          существующем Клиенте, Компания предпринимает шаги для обеспечения
          того, чтобы вся необходимая информация была получена как можно
          быстрее.
        </p>
        <p>
          Компания имеет право полагаться на шаги по идентификации и проверке,
          которые она уже предприняла, если у нее нет сомнений в достоверности
          этой информации.
        </p>
        <p>
          Постоянный мониторинг совершенных транзакций и Деловых Отношений
          осуществляется с целью оценки степени, в которой сделки и
          деятельность, осуществляемые Клиентом, соответствуют его
          обстоятельствам и бизнесу, а также предполагаемым Деловым Отношениям.
        </p>
        <p>
          При проведении постоянного мониторинга Компания должна рассматривать
          как представляющие повышенный риск:
        </p>
        <ul>
          <li>комплексные транзакции;</li>
          <li>необычные крупные транзакции;</li>
          <li>
            необычные паттерны транзакций, не имеющие очевидной экономической
            или законной цели;
          </li>
          <li>
            деятельность и транзакции:
            <ul>
              <li>
                связанные со странами, которые не применяют или недостаточно
                применяют Рекомендации ФАТФ; или
              </li>
              <li>
                которые являются предметом контрмер Организации Объединенных
                Наций или Европейского союза; и
              </li>
            </ul>
          </li>
          <li>
            деятельность и операции, которые могут осуществляться с лицами, в
            отношении которых действуют санкции и меры Организации Объединенных
            Наций или Европейского союза.
          </li>
        </ul>
        <h4>4. УПРОЩЕННЫЕ МЕРЫ НАДЛЕЖАЩЕЙ ПРОВЕРКИ («УНП»)</h4>
        <p>
          Компания не обязана применять меры НПК перед установлением Деловых
          отношений или выполнением разовой сделки, если у нее есть разумные
          основания полагать, что Клиент:
        </p>
        <ul>
          <li>поставщик услуг;</li>
          <li>иностранное регулируемое лицо;</li>
          <li>орган государственной власти в государстве; или</li>
          <li>
            компания, ценные бумаги которой котируются на признанной бирже. Эти
            положения не применяются, если:
          </li>
          <li>
            поставщик услуг подозревается в отмывании денег или финансировании
            терроризма;
          </li>
          <li>
            Клиент находится или проживает в стране, которая не применяет или
            применяет в недостаточной мере Рекомендации ФАТФ; или
          </li>
          <li>
            выявлен более высокий риск отмывания денег или финансирования
            терроризма.
          </li>
        </ul>
        <p>
          Если Клиент не применяет меры НПК до установления Деловых отношений
          или совершения разовой операции в соответствии с настоящим положением,
          Компания должна получить и сохранить документацию, подтверждающую, что
          данное положение применяется.
        </p>
        <h4>5. РАСШИРЕННЫЕ МЕРЫ НПК И ПОСТОЯННЫЙ МОНИТОРИНГ</h4>
        <p>
          Компания должна с учетом риска применять меры Расширенной Надлежащей
          Проверки («РНП») и проводить усиленный постоянный мониторинг:
        </p>
        <ul>
          <li>
            если Компания имеет или намеревается установить деловые отношения
            или предполагает осуществлять случайные сделки с лицом, связанным со
            страной, которая не применяет или применяет в недостаточной мере
            Рекомендации ФАТФ;
          </li>
          <li>
            если Компания имеет или намеревается установить деловые отношения с
            иностранным Политически Значимым Лицом («ПЗЛ») или членом семьи или
            близким партнером иностранного Политически Значимого Лица или
            осуществлять разовые сделки с ним;
          </li>
          <li>
            если любое из следующих лиц является иностранным Политически
            Значимым Лицом или членом семьи или близким партнером иностранного
            Политически Значимого Лица:
            <ul>
              <li>бенефициарный владелец Клиента;</li>
              <li>третье лицо, от имени которого действует Клиент;</li>
              <li>
                бенефициарный владелец третьего лица, от имени которого
                действует Клиент;
              </li>
              <li>
                лицо, действующее или намеревается действовать от имени Клиента;
              </li>
            </ul>
          </li>
          <li>
            когда Клиент, транзакция или Деловые Отношения включают:
            <ul>
              <li>
                частный банкинг, юридические лица или образования, включая
                трасты, которые являются средствами хранения личных активов; или
              </li>
              <li>
                компании, имеющие номинальных акционеров или акции на
                предъявителя; и
              </li>
            </ul>
          </li>
          <li>
            в любой другой ситуации, которая по своему характеру может
            представлять более высокий риск отмывания денег или финансирования
            терроризма.
          </li>
        </ul>
        <h4>5.1. Страны с Высоким Риском</h4>
        <p>
          Некоторые страны связаны с такими преступлениями, как незаконный
          оборот наркотиков, мошенничество и коррупция, и, следовательно,
          представляют более высокий потенциальный риск для Компании. Ведение
          деловых отношений с Клиентом из такой страны подвергает Компанию
          репутационному и юридическому риску.
        </p>
        <p>
          Компания должна проявлять дополнительную осторожность и проводить РНП
          в отношении физических и/или юридических лиц, находящихся в странах с
          высоким уровнем риска.
        </p>
        <p>
          Компания проявляет осторожность в отношении принятия сертифицированной
          документации от физических/юридических лиц, находящихся в
          странах/территориях с высоким уровнем риска, и соответствующих
          проверок, проводимых в отношении таких физических/юридических лиц, для
          обеспечения их законности и надежности.
        </p>
        <p>
          При оценке риска страны Компания учитывает, среди прочего, санкции,
          введенные ООН и Великобританией, юрисдикции ФАТФ высокого риска и не
          сотрудничающие юрисдикции, ФАТФ и ее региональные органы (FSRB), такие
          как отчеты о взаимной оценке MoneyVal, и Индекс восприятия коррупции
          Transparency International.
        </p>
        <p>Веб-сайты, которые использует Компания, включают:</p>
        <ul>
          <li>
            веб-сайт ФАТФ по адресу{" "}
            <a href="www.fatf-gafi.org">www.fatf-gafi.org</a>;
          </li>
          <li>
            Сеть Борьбы с Финансовыми Преступлениями (FinCEN) на{" "}
            <a href="https://www.fincen.gov/">https://www.fincen.gov/</a> для
            получения рекомендаций по странам;
          </li>
          <li>
            правление по Контролю за Иностранными Активами (OFAC){" "}
            <a href="www.treas.gov/ofac">www.treas.gov/ofac</a> для получения
            информации, касающейся внешней политики и национальной безопасности
            США; и
          </li>
          <li>
            Transparency International, www.transparency.org для получения
            информации о странах, подверженных коррупции.
          </li>
        </ul>
        <h4>6. ПОЛИТИЧЕСКИ ЗНАЧИМЫЕ ЛИЦА</h4>
        <p>
          Деловые отношения с лицами, занимающими важные государственные
          должности, а также с лицами или компаниями, явно связанными с ними,
          могут подвергать Компанию значительному репутационному и/или
          юридическому риску. Риск возникает, когда такие лица злоупотребляют
          своими публичными полномочиями либо в личных целях, либо в интересах
          других посредством незаконных действий, таких как получение взяток или
          мошенничество. Таких лиц обычно называют «Политически Значимыми
          Лицами» или «властителями», включая глав государств, министров,
          влиятельных государственных деятелей, судей и военачальников.
        </p>
        <p>
          Ссылка на Политически Значимых Лиц в настоящей Политике включает
          членов их семей и близких партнеров. Членами семьи Политически
          Значимого Лица являются лица, связанные с Политически Значимым Лицом
          либо напрямую (кровное родство), либо через брак или аналогичные
          (гражданские) формы партнерства. Близкими партнерами Политически
          Значимых Лиц являются лица, которые тесно связаны с Политически
          Значимыми Лицами в социальном или профессиональном плане.
        </p>
        <p>
          Компания проявляет бдительность в отношении Политически Значимых Лиц
          из всех юрисдикций, стремящихся установить Деловые Отношения. В
          отношении Политически Значимых Лиц Компания, в дополнение к обычным
          мерам Надлежащей Проверки:
        </p>
        <ul>
          <li>
            имеет соответствующие системы управления рисками для определения
            того, является ли Клиент Политически Значимым Лицом;
          </li>
          <li>
            получает одобрение высшего руководства на установление Деловых
            Отношений с такими Клиентами;
          </li>
          <li>
            принимает разумные меры для установления источника богатства и
            источника средств; и
          </li>
          <li>проводит расширенный постоянный мониторинг Деловых Отношений.</li>
        </ul>
        <p>
          Компания получает одобрение высшего руководства на продолжение Деловых
          Отношений после того, как Клиент или бенефициарный владелец
          оказывается или впоследствии становится Политически Значимым Лицом.
        </p>
        <p>
          Компания использует риск-ориентированный подход для определения
          характера и масштабов Расширенной Надлежащей Проверки в тех случаях,
          когда риски ОД/ФТ высоки. При оценке рисков ОД/ФТ, связанных с
          Политически Значимыми Лицами, Компания принимает во внимание такие
          факторы, как: может ли Клиент, являющийся Политически Значимым Лицом:
        </p>
        <ul>
          <li>из страны с высоким уровнем риска;</li>
          <li>
            имеет видные государственные функции в секторах, которые, как
            известно, подвержены коррупции; и
          </li>
          <li>
            имеет деловые интересы, которые могут вызвать конфликт интересов (с
            занимаемой должностью).
          </li>
        </ul>
        <p>
          Другие тревожные сигналы, которые Компания считает важными, включают
          (в дополнение к вышеперечисленным и тревожным сигналам, которые она
          рассматривает для других заявителей):
        </p>
        <ul>
          <li>
            информация, предоставляемая Политически Значимым Лицом, не
            соответствует другой (общедоступной) информации, такой как
            декларации об имуществе и опубликованные официальные зарплаты;
          </li>
          <li>
            средства постоянно перемещаются в страны и из стран, с которыми у
            Политически Значимого Лица, похоже, нет связей;
          </li>
          <li>
            Политически Значимое Лицо использует несколько банковских счетов без
            видимых коммерческих или иных причин;
          </li>
          <li>
            Политически Значимое Лицо из страны, которая запрещает или
            ограничивает определенным гражданам иметь счета или владеть
            определенной собственностью в иностранном государстве.
          </li>
        </ul>
        <h4>7. ОБЯЗАННОСТЬ ОТЧЕТНОСТИ</h4>
        <p>
          Компания требует, чтобы сотрудник по отчетности в сфере ПОД/ФТ
          направлял внешние отчеты о подозрительной деятельности непосредственно
          в Подразделение
        </p>
        <p>
          Финансовой Разведки, как только это будет практически возможно, и в
          любом случае в течение 14 дней после того, как информация или другой
          вопрос станет достоянием сотрудника по вопросам соблюдения требований
          ПОД/ФТ, что :
        </p>
        <ul>
          <li>
            в такой форме, которая может быть предписана или определена
            Подразделением Финансовой Разведки; и
          </li>
          <li>
            включать следующую информацию:
            <ul>
              <li>
                полную информацию о Клиенте и как можно более полное изложение
                информации, дающей основания для осведомленности, подозрения или
                разумных оснований для осведомленности или подозрения;
              </li>
              <li>
                если есть подозрения в совершении определенного вида преступного
                деяния, заявление об этом деянии;
              </li>
              <li>
                если у Компании есть дополнительные соответствующие
                доказательства, которые могут быть предоставлены, характер этих
                доказательств; и
              </li>
              <li>
                такую статистическую информацию, которая может потребоваться
                Подразделению Финансовой Разведки.
              </li>
            </ul>
          </li>
        </ul>
        <h4>7.1. Специалист по отчетности по ПОД/ФТ</h4>
        <p>
          Компания назначает лицо, утвержденное надзорным органом, ответственным
          за отчетность по ПОД/ФТ. Основными функциями ответственного за
          отчетность по ПОД/ФТ являются:
        </p>
        <ul>
          <li>
            получать и рассматривать внутреннюю информацию об отмывании денег и
            финансировании терроризма;
          </li>
          <li>
            рассмотреть вопрос о том, следует ли направлять отчет о
            подозрительной деятельности в Подразделению Финансовой Разведки; и
          </li>
          <li>
            если он или она считает, что необходимо составить отчет о
            подозрительной деятельности, представить отчет в Подразделение
            Финансовой Разведки.
          </li>
        </ul>
        <h4>7.2. Специалист по соблюдению требований ПОД/ФТ</h4>
        <p>
          Компания назначает лицо, утвержденное надзорным органом, в качестве
          ответственного за соблюдение требований ПОД/ФТ.
        </p>
        <p>
          Основная функция сотрудника по соблюдению требований ПОД/ФТ
          заключается в надзоре и мониторинге соблюдения Компанией Закона о
          доходах от преступной деятельности (2013 г.), всего действующего
          законодательства в отношении отмывания денег и финансирования
          терроризма, Регуляций и Законов.
        </p>
        <h4>7.3. Процедуры отчетности</h4>
        <p>
          <i>
            Компания устанавливает и поддерживает процедуры отчетности, которые:
          </i>
        </p>
        <ul>
          <li>сообщать своим сотрудникам личность ответственного за ПОД/ФТ;</li>
          <li>
            требовать, чтобы сотруднику по ПОД/ФТ сообщалось о любой информации
            или других вопросах, доведенных до сведения любого сотрудника,
            занимающегося соответствующим бизнесом, который, по мнению этого
            лица, дает основания для осведомленности, подозрений или разумных
            оснований для осведомленности или подозрение, что другое лицо
            занимается отмыванием денег или финансированием терроризма;
          </li>
          <li>
            требовать, чтобы отчет был незамедлительно рассмотрен ответственным
            за ПОД/ФТ в свете всей другой соответствующей информации с целью
            определения того, дает ли информация или иной вопрос, содержащийся в
            отчете, основания для получения сведений, подозрений или разумных
            оснований для получения сведений или подозрений в отмывании денег
            или финансировании терроризма;
          </li>
          <li>
            предоставить ответственному лицу по ПОД/ФТ доступ ко всей другой
            информации, которая может помочь при рассмотрении отчета;
          </li>
          <li>
            требовать, чтобы информация или другие сведения, содержащиеся в
            отчете, были раскрыты сотрудником по ПОД/ФТ, как только это
            практически возможно, в Подразделение финансовой разведки в
            письменной форме, если сотрудник по ПОД/ФТ знает, подозревает или
            имеет разумные основания знать или подозревать, что другое лицо
            занимается отмыванием денег или финансированием терроризма; и
          </li>
          <li>
            потребовать от ответственного лица по вопросам ПОД/ФТ сообщать в
            Подразделение Финансовой Разведки о попытках проведения операций и
            сделках, в которых было отказано (независимо от суммы попытки
            операции или стоимости отклоненной сделки), когда попытка операции
            или отклоненная сделка привели к пониманию, подозрению или другим
            разумным основаниям для подозрения в отмывании денег или
            финансировании терроризма.
          </li>
        </ul>
        <h4>8. ОБУЧЕНИЕ ПЕРСОНАЛА</h4>
        <p>
          Компания должна обеспечить обучение сотрудников распознаванию и
          обработке:
        </p>
        <ul>
          <li>
            транзакций, осуществляемых любым лицом или от его имени, которое
            занимается или предположительно занимается отмыванием денег или
            финансированием терроризма; и
          </li>
          <li>
            иное поведение, указывающее на то, что лицо занимается отмыванием
            денег или финансированием терроризма.
          </li>
        </ul>
        <p>
          Обучение должно включать предоставление информации о современных
          методах, методах, тенденциях и типологиях отмывания денег и
          финансирования терроризма.
        </p>
        <p>Компания должна:</p>
        <ul>
          <li>
            проводить соответствующее базовое обучение по вопросам ПОД/ФТ для
            сотрудников, чьи обязанности не связаны с ведением соответствующего
            бизнеса;
          </li>
          <li>
            установить и поддерживать процедуры, которые отслеживают и проверяют
            эффективность осведомленности своих сотрудников о ПОД/ФТ и
            предоставляемого им обучения;
          </li>
          <li>
            проверять компетентность и добросовестность сотрудников, чьи
            обязанности связаны с ведением соответствующего бизнеса, во время их
            приема на работу и при любом последующем изменении роли, а также
            обеспечивать, чтобы их компетентность и добросовестность находились
            под постоянным контролем;
          </li>
          <li>
            проводить обучение временного и контрактного персонала и, при
            необходимости, персонала любых третьих сторон, выполняющих функции в
            отношении поставщика услуг в соответствии с соглашением об
            аутсорсинге; и
          </li>
          <li>
            предоставить сотрудникам адекватную подготовку по распознаванию и
            обработке транзакций с соответствующей периодичностью.
          </li>
        </ul>
        <h4>9. ПРОЧИЕ ПОЛОЖЕНИЯ</h4>
        <p>
          Компания время от времени пересматривает настоящую Политику в целях
          соблюдения применимого законодательства.
        </p>
        <p>
          Компания сотрудничает с надзорными и правоохранительными органами в
          целях предотвращения отмывания денег и финансирования терроризма, тем
          самым передавая информацию, имеющуюся в распоряжении Компании, и
          отвечая на запросы в разумные сроки, соблюдая обязанности,
          обязательства и ограничения, вытекающие из законодательства. По любым
          соответствующим запросам, пожалуйста, свяжитесь с нами по адресу:{" "}
          <a href="compliance@starexchange.io">compliance@starexchange.io</a>.
        </p>
      </section>

      <Footer />
    </main>
  );
};

export default AML;
