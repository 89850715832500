import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const About = () => {
  return (
    <main className="privacy">
      <NavBar />

      <section className="privacy__content">
        <h2 className="text-left">О компании</h2>
        <p>
          Cryptoroyal.exchange предлагает простой и понятный интерфейс,
          безопасные операции и выгодные курсы обмена. Мы не взимаем скрытых или
          неправомерных платежей. Кроме того, мы гарантируем быстрое выполнение
          операций и оперативную поддержку пользователей. Мы работаем с
          различными валютами и платежными системами, чтобы обеспечить
          максимальную удобность и гибкость для наших клиентов. Наша цель -
          сделать процесс обмена валютой максимально простым и безопасным для
          всех пользователей.
        </p>
        <p>
          Cryptoroyal.exchange была основана в 2013 году и специализируется на
          предоставлении услуг по обмену электронных, цифровых и криптовалют, а
          также вводу-выводу активов на банковские карты. Мы придерживаемся
          принципа, что наш приоритет - это наш клиент. Мы искренне любим и
          ценим каждого клиента и делаем все возможное, чтобы обмен электронных
          валют был удобным и быстрым для каждого.
        </p>
        <p>
          Мы постоянно развиваемся и улучшаем наш сервис. У нас есть большая и
          сильная служба технической поддержки, которая поможет найти ответы на
          все вопросы. Мы делаем все, чтобы наш сервис был самым безопасным, и у
          наших пользователей никогда не было повода беспокоиться о сохранности
          их средств. Мы также делаем все, чтобы обмены были быстрыми, курсы -
          выгодными, а поддержка - заботливой.
        </p>
        <p>
          Cryptoroyal.exchange сотрудничает с мониторингами обменных пунктов,
          платежными системами, криптобиржами и придерживается международным
          требованиям AML\KYC\KYT. Мы готовы предоставить нашим клиентам
          высококачественный сервис и помочь им в любых вопросах, связанных с
          обменом электронных валют.
        </p>
        <p>
          Если у вас есть какие-либо вопросы или предложения, пожалуйста,
          свяжитесь с нами, и мы с радостью поможем вам. Спасибо, что выбрали
          Cryptoroyal.exchange!
        </p>
      </section>
      <Footer />
    </main>
  );
};

export default About;
