import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { CurrenciesArray } from "../components/CurrenciesArray";
import { Button } from "antd";

const Currencies = () => {
  return (
    <main className="currencies">
      <NavBar />

      <section className="currencies__content">
        <div className="currency__header">
          <h1>Поддерживаемые валюты</h1>
          <div className="currency__header__input">
            <input type="text" placeholder="Поиск по валютам" />
          </div>
        </div>
        <div className="currency__list">
          {CurrenciesArray.map((currency, i) => (
            <div className="currency__item" key={i}>
              <div className="currency__item__icon">
                <img src={currency.icon} alt={currency.name} />
              </div>
              <div className="currency__item__info">
                <span className="currency__info__name">{currency.name}</span>
                <span className="currency__info__symbol">
                  {currency.symbol}
                </span>
              </div>
              <div className="currency__item__action">
                <Button>Купить</Button>
                <Button>Продать</Button>
              </div>
            </div>
          ))}
        </div>
      </section>

      <Footer />
    </main>
  );
};

export default Currencies;
