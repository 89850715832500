export const CurrenciesArray = [
  {
    name: "USD Tether",
    symbol: "TRC-20 (TRX)",
    icon: require("../assets/img/currencies/usdt.svg").default,
  },
  {
    name: "Raiffeisen",
    symbol: "UAH",
    icon: require("../assets/img/currencies/raiffeisen.svg").default,
  },
  {
    name: "Privat24",
    symbol: "UAH",
    icon: require("../assets/img/currencies/privat24.svg").default,
  },
  {
    name: "Oschadbank",
    symbol: "UAH",
    icon: require("../assets/img/currencies/oschadbank.svg").default,
  },
  {
    name: "UkrSibbank",
    symbol: "UAH",
    icon: require("../assets/img/currencies/ukrsibbank.svg").default,
  },
  {
    name: "PUMB",
    symbol: "UAH",
    icon: require("../assets/img/currencies/pumb.svg").default,
  },
  {
    name: "Monobank",
    symbol: "UAH",
    icon: require("../assets/img/currencies/monobank.svg").default,
  },
  {
    name: "Sense Bank",
    symbol: "UAH",
    icon: require("../assets/img/currencies/sensebank.svg").default,
  },
  {
    name: "OTP Bank",
    symbol: "UAH",
    icon: require("../assets/img/currencies/otpbank.svg").default,
  },
  {
    name: "A Bank",
    symbol: "UAH",
    icon: require("../assets/img/currencies/abank.svg").default,
  },
  {
    name: "Izibank",
    symbol: "UAH",
    icon: require("../assets/img/currencies/izibank.svg").default,
  },
  {
    name: "NeoBank",
    symbol: "UAH",
    icon: require("../assets/img/currencies/neobank.svg").default,
  },
  {
    name: "Visa/MasterCard",
    symbol: "UAH",
    icon: require("../assets/img/currencies/mastercard.svg").default,
  },
  {
    name: "Visa/MasterCard",
    symbol: "KZT",
    icon: require("../assets/img/currencies/mastercard.svg").default,
  },
  {
    name: "HalykBank",
    symbol: "KZT",
    icon: require("../assets/img/currencies/halykbank.svg").default,
  },
  {
    name: "Bereke Bank",
    symbol: "KZT",
    icon: require("../assets/img/currencies/berekebank.svg").default,
  },
  {
    name: "ForteBank",
    symbol: "KZT",
    icon: require("../assets/img/currencies/fortebank.svg").default,
  },
  {
    name: "Kaspi Bank",
    symbol: "KZT",
    icon: require("../assets/img/currencies/kaspibank.svg").default,
  },
  {
    name: "Jysan Bank",
    symbol: "KZT",
    icon: require("../assets/img/currencies/jysanbank.svg").default,
  },
  {
    name: "CenterCredit",
    symbol: "KZT",
    icon: require("../assets/img/currencies/centercredit.png"),
  },
  {
    name: "Eurasian Bank",
    symbol: "KZT",
    icon: require("../assets/img/currencies/eurasianbank.png"),
  },
  {
    name: "Freedom Bank",
    symbol: "KZT",
    icon: require("../assets/img/currencies/fortebank.svg").default,
  },
  {
    name: "Visa/MasterCard",
    symbol: "AZN",
    icon: require("../assets/img/currencies/mastercard.svg").default,
  },
  {
    name: "Visa/MasterCard",
    symbol: "GEL",
    icon: require("../assets/img/currencies/mastercard.svg").default,
  },
  {
    name: "Visa/MasterCard",
    symbol: "AMD",
    icon: require("../assets/img/currencies/mastercard.svg").default,
  },
  {
    name: "M10",
    symbol: "AZN",
    icon: require("../assets/img/currencies/m10.svg").default,
  },
  {
    name: "Visa/MasterCard",
    symbol: "MDL",
    icon: require("../assets/img/currencies/mastercard.svg").default,
  },
  {
    name: "Visa/MasterCard",
    symbol: "EUR",
    icon: require("../assets/img/currencies/mastercard.svg").default,
  },
  {
    name: "Wise",
    symbol: "EUR",
    iicon: require("../assets/img/currencies/wise.svg").default,
  },
  {
    name: "Revolut",
    symbol: "EUR",
    icon: require("../assets/img/currencies/revolut.svg").default,
  },
  {
    name: "PaySera",
    symbol: "EUR",
    icon: require("../assets/img/currencies/paysera.svg").default,
  },
  {
    name: "Sepa",
    symbol: "EUR",
    icon: require("../assets/img/currencies/sepa.svg").default,
  },
  {
    name: "Wire",
    symbol: "EUR",
    icon: require("../assets/img/currencies/wire.svg").default,
  },
  {
    name: "Cash",
    symbol: "EUR",
    icon: require("../assets/img/currencies/cash.svg").default,
  },
  {
    name: "Cash",
    symbol: "USD",
    icon: require("../assets/img/currencies/cash.svg").default,
  },
  {
    name: "Cash",
    symbol: "UAH",
    icon: require("../assets/img/currencies/cash.svg").default,
  },
  {
    name: "Cash",
    symbol: "KZT",
    icon: require("../assets/img/currencies/cash.svg").default,
  },
  {
    name: "Cash",
    symbol: "TRY",
    icon: require("../assets/img/currencies/cash.svg").default,
  },
  {
    name: "Cash",
    symbol: "CZK",
    icon: require("../assets/img/currencies/cash.svg").default,
  },
  {
    name: "Cash",
    symbol: "PLN",
    icon: require("../assets/img/currencies/cash.svg").default,
  },
  {
    name: "Cash",
    symbol: "KGS",
    icon: require("../assets/img/currencies/cash.svg").default,
  },
  {
    name: "Cash",
    symbol: "IDR",
    icon: require("../assets/img/currencies/cash.svg").default,
  },
  {
    name: "Cash",
    symbol: "ILS",
    icon: require("../assets/img/currencies/cash.svg").default,
  },
  {
    name: "Cash",
    symbol: "MDL",
    icon: require("../assets/img/currencies/cash.svg").default,
  },
  {
    name: "Cash",
    symbol: "AED",
    icon: require("../assets/img/currencies/cash.svg").default,
  },
  {
    name: "Wire",
    symbol: "UAH",
    icon: require("../assets/img/currencies/wire.svg").default,
  },
  {
    name: "Visa/MasterCard",
    symbol: "TRY",
    icon: require("../assets/img/currencies/mastercard.svg").default,
  },
  {
    name: "Wire",
    symbol: "TRY",
    icon: require("../assets/img/currencies/wire.svg").default,
  },
  {
    name: "Cash",
    symbol: "THB",
    icon: require("../assets/img/currencies/cash.svg").default,
  },
  {
    name: "Visa/MasterCard",
    symbol: "USD",
    icon: require("../assets/img/currencies/mastercard.svg").default,
  },
];
