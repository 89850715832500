import React, { useState } from "react";
import { Header } from "antd/es/layout/layout";

function NavBar({ className }) {
  const [isClosed, setIsClosed] = useState(false);

  return (
    <>
      <div className={`top__info ${isClosed ? "closed" : ""}`}>
        <h4>Внимание!</h4>
        <p>
          Внимание! Заявки UAH {"->"} USDT обрабатываются с помощью KYC через
          операторов поддержки. После создания заявки напишите пожалуйста в чат
          поддержки для проведения обмена.
        </p>
        <div className="close" onClick={() => setIsClosed(true)}></div>
      </div>
      <Header className={className}>
        <section>
          <div className="head">
            <a href="/" className="logo">
              <img src={require("../assets/img/logo.svg").default} alt="" />
            </a>
            <div className="search">
              <svg
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19.4611 20.4431C17.5026 22.1724 14.9296 23.2222 12.1111 23.2222C5.97504 23.2222 1 18.2472 1 12.1111C1 5.97504 5.97504 1 12.1111 1C18.2472 1 23.2222 5.97504 23.2222 12.1111C23.2222 14.9296 22.1724 17.5026 20.4431 19.4611L25.7965 24.8146C26.0678 25.0858 26.0678 25.5253 25.7965 25.7965C25.5253 26.0678 25.0858 26.0678 24.8146 25.7965L19.4611 20.4431ZM21.8333 12.1111C21.8333 17.4808 17.4808 21.8333 12.1111 21.8333C6.74137 21.8333 2.38889 17.4808 2.38889 12.1111C2.38889 6.74137 6.74137 2.38889 12.1111 2.38889C17.4808 2.38889 21.8333 6.74137 21.8333 12.1111Z"
                  fill="#9F9F9F"
                  stroke="#9F9F9F"
                  strokeWidth="0.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </div>
            <div className="menu">
              <a href="/">Быстрый обмен</a>
            </div>
          </div>
        </section>
      </Header>
    </>
  );
}

export default NavBar;
