import React from "react";

const Footer = () => {
  return (
    <footer>
      <section>
        <div className="item__inner">
          <div className="item">
            <img
              src={require("../assets/img/partner/partner1.svg").default}
              alt=""
            />
          </div>
          <div className="item">
            <img
              src={require("../assets/img/partner/partner4.svg").default}
              alt=""
            />
          </div>
          <div className="item">
            <img
              src={require("../assets/img/partner/scanbit.svg").default}
              alt=""
            />
          </div>
          <div className="item">
            <img
              src={require("../assets/img/partner/partner-kurs.svg").default}
              alt=""
            />
          </div>
          <div className="item">
            <img
              src={require("../assets/img/partner/partner-profinvest.png")}
              alt=""
            />
          </div>
        </div>
      </section>
      <section>
        <div className="links">
          <ul>
            <li>
              <a href="/">О нас</a>
            </li>
            <li>
              <a href="/about">О компании</a>
            </li>
            <li>
              <a href="/supported-currencies">Поддерживаемые валюты</a>
            </li>
          </ul>

          <ul>
            <li>
              <a href="/privacy-policy">Политика конфиденциальности</a>
            </li>
            <li>
              <a href="/aml-policy">Политика AML</a>
            </li>
            <li>
              <a href="/terms-of-use">Условия использования</a>
            </li>
          </ul>

          <div className="links__info">
            <div className="online">
              Онлайн{" "}
              <img src={require("../assets/img/info.svg").default} alt="" />
            </div>
            <div className="online-text">
              Мы работаем сейчас. Наши рабочие часы: 08:00 - 21:00 по часовому
              поясу: Europe/Kiev. Среднее время обработки заявки составляет 2
              часа.
            </div>
            <div className="lang">
              RU
              <img
                src={require("../assets/img/arrow-down.svg").default}
                alt=""
              />
            </div>
            <a href="mailto:info@cryptoroyal.exchange">
              <svg
                width="19"
                height="15"
                viewBox="0 0 19 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.3693 0H0.630704C0.463449 0.000199869 0.303104 0.0680497 0.18486 0.188658C0.0666159 0.309267 0.000133193 0.472781 2.58854e-06 0.643314V1.93749C2.58854e-06 1.99712 0.0634182 2.06555 0.115236 2.09322L9.43905 7.52327C9.46294 7.53719 9.49001 7.54439 9.51752 7.54415C9.54564 7.54423 9.57325 7.53659 9.59747 7.52201L18.6395 2.09851C18.6903 2.06957 18.8251 1.99309 18.8756 1.95787C18.9368 1.91535 19 1.87686 19 1.80062V0.643062C18.9998 0.472573 18.9333 0.309124 18.8151 0.18857C18.6968 0.0680153 18.5365 0.000199753 18.3693 0Z"
                  fill="#EECB12"
                />
                <path
                  d="M18.9213 3.88881C18.897 3.8747 18.8695 3.86736 18.8416 3.86754C18.8136 3.86771 18.7862 3.8754 18.7621 3.88982L13.6452 6.95946C13.6248 6.97158 13.6074 6.98821 13.5941 7.00814C13.5809 7.02808 13.5723 7.05079 13.5688 7.07459C13.5654 7.0984 13.5673 7.12268 13.5743 7.14565C13.5813 7.16862 13.5933 7.18969 13.6094 7.20728L18.7271 12.8321C18.7418 12.8483 18.7596 12.8612 18.7794 12.8701C18.7992 12.8789 18.8207 12.8835 18.8423 12.8834C18.8841 12.8833 18.9242 12.8664 18.9537 12.8362C18.9833 12.8061 18.9999 12.7652 19 12.7226V4.02845C19.0001 4.00017 18.9929 3.97236 18.9791 3.94784C18.9652 3.92332 18.9453 3.90296 18.9213 3.88881Z"
                  fill="#EECB12"
                />
                <path
                  d="M12.1706 7.99022C12.1463 7.96325 12.1137 7.94559 12.0782 7.94018C12.0426 7.93478 12.0064 7.94196 11.9754 7.96053L9.92442 9.19106C9.80425 9.26185 9.66809 9.29956 9.52931 9.30049C9.39053 9.30141 9.2539 9.26552 9.13283 9.19634L7.32783 8.14495C7.29869 8.12803 7.26503 8.121 7.23171 8.12489C7.19839 8.12878 7.16715 8.14339 7.14252 8.16659L0.28821 14.649C0.270241 14.6662 0.256444 14.6873 0.24792 14.7109C0.239396 14.7344 0.23638 14.7596 0.239112 14.7845C0.241844 14.8095 0.250249 14.8334 0.263657 14.8544C0.277065 14.8755 0.295105 14.893 0.31634 14.9057C0.422937 14.9696 0.525834 15 0.630457 15H18.1877C18.2184 15.0001 18.2485 14.9909 18.2741 14.9737C18.2998 14.9565 18.3199 14.9319 18.332 14.9031C18.3443 14.8745 18.3482 14.8428 18.343 14.812C18.3378 14.7812 18.3239 14.7526 18.3029 14.7298L12.1706 7.99022Z"
                  fill="#EECB12"
                />
                <path
                  d="M5.63436 7.39974C5.65252 7.38263 5.66647 7.3614 5.6751 7.33778C5.68372 7.31415 5.68678 7.28879 5.68402 7.26374C5.68125 7.23869 5.67275 7.21465 5.6592 7.19356C5.64564 7.17248 5.62742 7.15494 5.60599 7.14237L0.235158 4.01461C0.211189 4.00073 0.184067 3.99352 0.156505 3.99368C0.128943 3.99385 0.101906 4.00139 0.0780982 4.01555C0.0542908 4.02971 0.0345474 4.05 0.0208424 4.07438C0.00713742 4.09877 -4.8732e-05 4.12639 2.51607e-06 4.15449V12.3613C-0.000171388 12.3927 0.00867308 12.4234 0.0254411 12.4497C0.0422091 12.4759 0.0661645 12.4966 0.0943425 12.5091C0.12252 12.5217 0.153684 12.5255 0.183975 12.5202C0.214267 12.5148 0.242357 12.5005 0.264769 12.4791L5.63436 7.39974Z"
                  fill="#EECB12"
                />
              </svg>
              info@cryptoroyal.exchange
            </a>
            <a href="https://t.me/cryptoroyalexch">
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.1394 0.647912L1.34483 7.34562C0.889097 7.53768 0.883864 8.18508 1.33644 8.38457L3.82355 9.36126C4.97897 9.76849 6.19921 9.61787 7.14688 9.01392L13.9776 4.46313C14.2663 4.26306 14.509 4.5096 14.3921 4.62946L9.0709 10.087C8.63146 10.5377 8.71857 11.2814 9.25012 11.6171L15.0878 15.304C15.8582 15.7906 16.8722 15.3246 17.0106 14.4204L18.9803 1.5461C19.1771 0.434811 17.8483 0.349166 17.1394 0.647912Z"
                  stroke="#EECB12"
                />
              </svg>
              @cryptoroyalexch
            </a>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
