import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const Privacy = () => {
  return (
    <main className="privacy">
      <NavBar />

      <section className="privacy__content">
        <h2>Политика конфиденциальности</h2>
        <p>
          Мы стремимся защищать вашу конфиденциальность и обеспечивать
          безопасность ваших персональных данных. В настоящей Политике
          конфиденциальности изложены правила сбора, использования и защиты
          информации, которую вы предоставляете при использовании нашего
          веб-сайта или услуг. Пользуясь нашими услугами, вы соглашаетесь с
          условиями, изложенными в этой политике. Мы рекомендуем внимательно
          ознакомиться с Политикой конфиденциальности, чтобы понять, как
          обрабатываются ваши данные.
        </p>
        <h4>1. Введение</h4>
        <p>
          В настоящей Политике конфиденциальности («Политика
          конфиденциальности») описаны наши методы обеспечения
          конфиденциальности в отношении деятельности, изложенной ниже. В
          соответствии с Вашими правам и мы информируем Вас о том, как мы
          собираем, храним, получаем доступ и иным образом обрабатываем
          информацию, касающуюся отдельных лиц. В настоящей Политике под
          «Персональными данными» понимается информация, которая сама по себе
          или в сочетании с другой доступной информацией позволяет отличить
          физическое лицо.
        </p>
        <p>
          Мы стремимся защищать вашу конфиденциальность в соответствии с самым
          высоким уровнем регулирования конфиденциальности. В целях соблюдения
          правовых норм, регулирующих защиту персональных данных, включая GDPR,
          который является общим европейским регламентом защиты данных, мы
          являемся контролерами данных, которые мы получаем и обрабатываем,
          чтобы Вы могли безопасно пользоваться нашими услугами. Эта политика
          применяется ко всем нашим веб-сайтам и доменам.
        </p>
        <p>
          Настоящая Политика не распространяется на сторонние приложения,
          веб-сайты, продукты, услуги или платформы, доступ к которым можно
          получить по ссылкам, которые мы можем Вам предоставить. Эти сайты
          принадлежат и управляются независимо от нас, и у них есть свои
          собственные методы обеспечения конфиденциальности и сбора данных.
          Любые личные данные, которые Вы предоставляете этим веб-сайтам, будут
          регулироваться собственной политикой конфиденциальности третьей
          стороны. Мы не можем принимать на себя ответственность за действия или
          политику этих независимых сайтов, а также не несем ответственности за
          контент или политику конфиденциальности таких сайтов.
        </p>
        <p>
          Настоящая Политика конфиденциальности применяется, когда Вы
          взаимодействуете с нами, выполняя любое из следующих действий:
        </p>
        <ul>
          <li>
            Используйте наш сайт или приложение и услуги в качестве
            авторизованного пользователя.
          </li>
          <li>
            Посещаете любой из наших веб-сайтов, на которых имеется ссылка на
            настоящее Положение о конфиденциальности.
          </li>
          <li>
            Получаете от нас любые сообщения, включая информационные бюллетени,
            электронные письма или текстовые сообщения.
          </li>
        </ul>
        <h4>2. Персональные данные, которые мы собираем</h4>
        <p>
          <b>Данные, которые Вы предоставляете</b>
        </p>
        <p>
          Когда Вы делаете заявку или пытаетесь заполнить заявку, мы собираем
          персональные данные как часть информации о Вашей заявке.
        </p>
        <p>Эти данные включают в себя:</p>
        <ul>
          <li>
            Информация об учетной записи, такая как Ваше Имя, адрес электронной
            почты, зашифрованный пароль, номер телефона.
          </li>
          <li>
            Если Вы предоставляете нам или нашим поставщикам услуг какие-либо
            Персональные данные, относящиеся к другим лицам, Вы заявляете, что
            имеете на это полномочия, и подтверждаете, что они будут
            использоваться в соответствии с настоящим Заявлением о
            конфиденциальности. Если Вы считаете, что ваш Персональный
          </li>
          <li>
            Данные были предоставлены нам ненадлежащим образом, или чтобы иным
            образом реализовать свои права, связанные с Вашими Персональными
            данными, свяжитесь с нами, используя информацию, указанную в разделе
            «Свяжитесь с нами» ниже.
          </li>
        </ul>
        <p>
          <b>Данные об устройстве и использовании</b>
        </p>
        <p>
          Когда Вы посещаете наш веб-сайт, мы автоматически собираем и сохраняем
          информацию о вашем посещении с помощью файлов cookie браузера (файлов,
          которые мы отправляем на ваш компьютер) или аналогичных технологий. Вы
          можете указать своему браузеру отказаться от всех файлов cookie или
          указать, когда файлы cookie отправляются. Функция справки в
          большинстве браузеров предоставит информацию о том, как принимать
          файлы cookie, отключать файлы cookie или уведомлять Вас о получении
          новых файлов cookie. Если Вы не принимаете файлы cookie, возможно, Вы
          не сможете использовать некоторые функции нашего Сервиса, и мы
          рекомендуем Вам оставить их включенными. Мы также обрабатываем
          информацию, когда Вы используете наши услуги и продукты. Эта
          информация может включать в себя:
        </p>
        <ul>
          <li>IP-адрес</li>
          <li>Отдельные продукты, которые Вы просматриваете</li>
          <li>
            Веб-термины или поисковые запросы, которые привели Вас на сайт.
          </li>
          <li>Часовой пояс</li>
        </ul>
        <p>
          <b>Данные, которые мы собираем от третьих лиц</b>
        </p>
        <p>
          Мы можем получать Ваши персональные данные от третьих лиц, таких как
          компании, подписывающиеся на услуги нашей компании, партнеры и другие
          источники. Эта информация собирается не нами, а третьей стороной, и на
          нее распространяются отдельные политики конфиденциальности и сбора
          данных соответствующей третьей стороны. Мы не имеем никакого контроля
          и информации о том, как Ваши персональные данные обрабатываются
          третьими лицами. Как всегда, Вы имеете право просмотреть и исправить
          эту информацию. Если у Вас есть какие-либо вопросы, Вам следует
          сначала обратиться к соответствующей третьей стороне для получения
          дополнительной информации о Ваших личных данных. Если третья сторона
          не отвечает на Ваши права, Вы можете связаться с уполномоченным по
          защите данных (контактные данные ниже), и мы сможем Вам помочь.
        </p>
        <p>
          Наши веб-сайты и сервисы могут содержать ссылки на другие веб-сайты,
          приложения и сервисы, поддерживаемые третьими лицами. Информационная
          практика таких других служб или сетей социальных сетей, в которых
          размещены наши фирменные страницы в социальных сетях, регулируется.
        </p>
        <h4>Законное требование</h4>
        <p>
          Мы можем использовать или раскрывать Ваши персональные данные для
          выполнения юридического обязательства, в связи с запросом
          государственного органа или в связи с судебным разбирательством или
          трибуналом, для предотвращения гибели людей или травм или для защиты
          наших прав или собственности. Там, где это возможно, мы сообщим Вам
          заранее о таком раскрытии.
        </p>
        <h4>Цель и правовая основа обработки персональных данных</h4>
        <p>
          Мы собираем и используем персональные данные о Вас с Вашего согласия,
          чтобы предоставлять, поддерживать и развивать наши продукты и услуги,
          а также понимать, как их улучшить
        </p>
        <p>Эти цели включают в себя:</p>
        <ul>
          <li>Доставка нашего продукта или услуги;</li>
          <li>
            Предоставление, разработка и улучшение наших продуктов и услуг;
          </li>
          <li>
            Предоставление, поддержка, отлаживание и улучшение наших продуктов и
            услуг;
          </li>
          <li>
            Предоставление Вам доступа к услугам компании и настройке учетных
            записей;
          </li>
          <li>Предоставить Вам техническую и клиентскую поддержку;</li>
          <li>Организация и проведение рекламы и маркетинга;</li>
          <li>
            Отправлять Вам информационные бюллетени и другие маркетинговые
            сообщения о текущих и будущих продуктах, программах и услугах,
            мероприятиях, конкурсах, опросах и рекламных акциях, проводимых нами
            или проводимых от нашего имени; и организовывать мероприятия или
            регистрировать участников и планировать встречи для мероприятий;
          </li>
        </ul>
        <p>
          Когда мы обрабатываем Ваши персональные данные для предоставления
          продукта или услуги, мы делаем это потому, что это необходимо для
          выполнения договорных обязательств. Вся вышеуказанная обработка
          необходима в наших законных интересах для предоставления продуктов и
          услуг, а также для поддержания наших отношений с Вам и и для защиты
          нашего бизнеса, например, от мошенничества. Для начала предоставления
          Вам услуг потребуется согласие;
        </p>
        <p>
          Новое согласие потребуется, если в тип собираемых данных будут внесены
          какие-либо изменения. В рамках нашего договора, если Вы не
          предоставите согласие, некоторые услуги могут быть Вам недоступны.
        </p>
        <h4>Обмен и раскрытие информации</h4>
        <p>
          Мы будем передавать Ваши персональные данные третьим лицам только
          способами, изложенными в настоящей Политике или установленными на
          момент сбора персональных данных. Мы также используем Google
          Analytics, чтобы понять, как наши клиенты используют сайт.
        </p>
        <p>
          Вы можете узнать больше о том, как Google использует вашу личную
          информацию здесь . Вы также можете отказаться от Google Analytics
          здесь.
        </p>
        <p>
          Мы также можем использовать вашу Персональную информацию для
          предоставления Вам целевого маркетинга посредством рекламы или
          коммуникаций (например, информационных бюллетеней). Для получения
          дополнительной информации о том, как работает таргетированная реклама,
          Вы можете посетить образовательные учреждения Network Advertising
          Initiative («NAI»), поставщиков электронных услуг и других третьих
          сторон.
        </p>
        <p>
          Мы можем использовать стороннего поставщика услуг, независимых
          подрядчиков, агентства или консультантов для предоставления и помощи
          нам в улучшении наших продуктов и услуг. Поставщики услуг могут
          находиться в ЕЭЗ или находиться за ее пределами. Мы можем передавать
          Ваши персональные данные маркетинговым агентствам , поставщикам услуг
          баз данных, поставщикам услуг резервного копирования и аварийного
          восстановления, поставщикам услуг электронной почты и другим лицам, но
          только для поддержки и улучшения наших продуктов и услуг. Для
          получения дополнительной информации о получателях Ваших Персональных
          данных свяжитесь с нами, используя информацию в разделе «Свяжитесь с
          нами» ниже.
        </p>
        <h4>Как реализовать свои права</h4>
        <p>
          Вы можете подать запрос на реализацию любого из этих прав в отношении
          Ваших личных данных, отправив запрос в нашу группу по обеспечению
          конфиденциальности, используя форму ниже.
        </p>
        <p>
          В целях Вашей конфиденциальности и безопасности мы по нашему
          усмотрению можем потребовать от Вас подтвердить свою личность перед
          предоставлением запрошенного запроса.
        </p>
        <h4>3. Политика использования файлов cookie</h4>
        <p>
          <b>Что такое файлы cookie?</b>
        </p>
        <p>
          Файл cookie — это небольшой файл с информацией, которую ваш браузер
          сохраняет на вашем устройстве. Информация в этом файле обычно
          передается владельцу сайта, а также потенциальным партнерам и третьим
          сторонам этого бизнеса. Сбор этой информации может быть использован в
          функциях сайта и/или для улучшения Вашего опыта.
        </p>
        <p>
          <b>Как мы используем файлы cookie?</b>
        </p>
        <p>
          Чтобы обеспечить Вам максимальное удобство, мы используем следующие
          типы файлов cookie: Строго необходимые. Как веб-приложение, нам
          требуются определенные файлы cookie для работы нашего сервиса.
        </p>
        <p>
          <b>Предпочтение</b>
        </p>
        <p>
          Мы используем файлы cookie предпочтений, чтобы запомнить, как Вы
          предпочитаете использовать наш сервис. Некоторые файлы cookie
          используются для персонализации контента и предоставления Вам
          индивидуального опыта. Например, местоположение может использоваться
          для предоставления Вам услуг и предложений в вашем районе.
        </p>
        <p>
          <b>Аналитика</b>
        </p>
        <p>
          Мы собираем аналитику о типах людей, которые посещают наш сайт, чтобы
          улучшить наш сервис и продукт.
        </p>
        <p>
          <b>Маркетинг</b>
        </p>
        <p>
          Мы передаем файлы cookie сторонним рекламодателям и/или партнерам,
          чтобы предоставить Вам персонализированный маркетинговый опыт.
        </p>
        <p>
          Мы также разрешаем сторонним партнерам размещать на нашем веб-сайте
          свои собственные файлы cookie.
        </p>
        <h4>4. Хранение и удаление</h4>
        <p>
          Мы будем хранить Ваши персональные данные только до тех пор, пока это
          необходимо для цели, для которой эти данные были собраны, и в объеме,
          требуемом действующим законодательством, но не более 5 лет. Когда нам
          больше не нужны персональные данные, мы удалим их из наших систем
          и/или предпримем шаги по их анонимизации.
        </p>
        <h4>5. Конфиденциальность детей</h4>
        <p>
          Мы не будем сознательно собирать персональные данные от детей в
          возрасте до 16 лет.
        </p>
        <h4>6. Ваши права на Ваши персональные данные</h4>
        <p>
          В зависимости от Вашего географического положения и гражданства Ваши
          права регулируются местными правилами конфиденциальности данных.
        </p>
        <p>Право на доступ (статья 15 GDPR)</p>
        <p>
          Вы имеете право запросить копию персональных данных, которые мы
          обрабатываем о Вас.
        </p>
        <p>Право на уточнение (статья 16 GDPR)</p>
        <p>
          Вы имеете право на исправление неполных или неточных персональных
          данных, которые мы обрабатываем о Вас.
        </p>
        <p>Право на удаление данных (статья 17 GDPR)</p>
        <p>
          Вы имеете право потребовать, чтобы мы удалили персональные данные,
          которые мы обрабатываем о Вас, за исключением того, что мы не обязаны
          это делать, если нам необходимо сохранить такие данные для соблюдения
          юридических обязательств или для установления, осуществления или
          защиты юридических требований.
        </p>
        <p>Право на ограничение обработки (статья 18 GDPR)</p>
        <p>
          Вы имеете право ограничить обработку нами Ваших личных данных, если Вы
          считаете, что такие данные являются неточными; наша обработка является
          незаконной; или если нам больше не нужно обрабатывать такие данные для
          определенной цели, за исключением случаев, когда мы не можем удалить
          данные из-за юридических или других обязательств, или потому, что Вы
          не хотите, чтобы мы их удалили.
        </p>
        <p>Право на переносимость данных (статья 20 GDPR)</p>
        <p>
          Вы имеете право получать личные данные, которые мы храним о Вас, в
          структурированном электронном формате и передавать такие данные
          другому контроллеру данных, если это (а) предоставленные Вами
          персональные данные нам, и (б) если мы обрабатываем эти данные на
          основании Вашего согласия или для выполнения договора с Вами или
          третьей стороной, которая подписывается на услуги компании.
        </p>
        <p>Право на возражение (статья 21 GDPR)</p>
        <p>
          Если юридическим обоснованием обработки нами Ваших персональных данных
          является наш законный интерес, Вы имеете право возражать против такой
          обработки на основаниях, связанных с Вашей конкретной ситуацией. Мы
          выполним ваш запрос, если только у нас не будет веских законных
          оснований для обработки, которые преобладают над Вашими интересами и
          пра Вам и, или если нам необходимо продолжить обработку данных для
          установления, осуществления или защиты юридического иска.
        </p>
        <p>
          Вы имеете право не получить отказ в обслуживании или получить
          измененный опыт в связи с выполнением любого из Ваших прав CCPA.
        </p>
        <p>Отзыв согласия</p>
        <p>
          Если Вы дали согласие на обработку Ваших персональных данных, Вы
          имеете право в любое время бесплатно отозвать свое согласие, например,
          если Вы хотите отказаться от подписки на маркетинговые сообщения,
          которые Вы получаете от нас. Если Вы хотите отозвать свое согласие,
          свяжитесь с нами, используя информацию, указанную внизу этой страницы.
        </p>
        <h4>7. Поправки</h4>
        <p>
          Политика представлена в самой актуальной версии. Мы можем в
          одностороннем порядке изменить настоящую Политику в любое время.
        </p>
        <p>
          Все изменения вступают в силу с момента их публикации на данной
          странице, если в тексте изменений не указан иной срок.
        </p>
        <p>
          При использовании наших услуг Вам будет предложено ознакомиться и
          принять нашу Политику конфиденциальности. Таким образом мы можем
          зафиксировать Ваше согласие и уведомить Вас о любых будущих изменениях
          в настоящей Политике.F
        </p>
        <p>
          Мы приложим все усилия для организации дополнительных способов
          оповещения пользователей об изменениях в Политике, но просим Вас
          самостоятельно регулярно проверять наличие актуальной версии на сайте.
        </p>
        <h4>8. Жалобы</h4>
        <p>
          Если у Вас есть жалоба на настоящую Политику или какой-либо элемент
          Вашей личной информации, которую мы храним, свяжитесь с нами ниже.
          Если Вы не удовлетворены, Вы имеете право подать жалобу в местный
          орган по защите данных.
        </p>
        <p>
          Если Вы находитесь в ЕЭЗ, посетите этот сайт для получения списка
          местных органов по защите данных.
        </p>
        <h4>9. Свяжитесь с нами</h4>
        <p>
          Чтобы запросить копию Вашей информации, отказаться от подписки на нашу
          рассылку, запросить удаление Ваших данных или задать вопрос о
          конфиденциальности Ваших данных, мы упростили этот процесс:
        </p>
        <p>Напишите нам по адресу:</p>
        <p>
          <a href="/">info@cryptoroyal.exchange</a>
        </p>
      </section>

      <Footer />
    </main>
  );
};

export default Privacy;
