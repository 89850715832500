import { http, createConfig } from 'wagmi';
import { bsc } from 'viem/chains';
import { walletConnect, injected } from 'wagmi/connectors';

export const settings = {
  projectId: 'e26e37fe571ce4e57507711459d872fb',
  metadata: {
    name: 'Cryptoroyal.Exchange',
    description: 'Cryptoroyal.Exchange',
    url: `https://${window.location.hostname}/`,
    icons: [`https://${window.location.hostname}/favicon.png`],
  },
};

export const chains = [bsc];

const connectors = [];
connectors.push(
  walletConnect({
    projectId: settings.projectId,
    metadata: settings.metadata,
    showQrModal: false,
  }),
);
connectors.push(injected({ shimDisconnect: true }));

export const wagmiConfig = createConfig({
  chains,
  transports: {
    [bsc.id]: http(),
  },
  connectors: connectors,
});
