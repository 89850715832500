import React from 'react';
// import { ConfigProvider } from 'antd';
// import ruRu from 'antd/es/locale/ru_RU';
import Routes from './router/Routes';
import { createAppKit } from '@reown/appkit/react';
import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiAdapter } from '@reown/appkit-adapter-wagmi';
import { bsc, tron } from '@reown/appkit/networks';
import { settings } from './config/wagmiConfig';

function App() {
  const client = new QueryClient();

  // Set the networks
  const networks = [bsc, tron];

  // Create Wagmi Adapter
  const wagmiAdapter = new WagmiAdapter({
    networks,
    projectId: settings.projectId,
    ssr: true,
  });

  // Create modal
  createAppKit({
    adapters: [wagmiAdapter],
    networks,
    projectId: settings.projectId,
    metadata: settings.metadata,
    features: {
      analytics: true,
      email: false,
      socials: false,
      emailShowWallets: false,
    },
  });

  return (
    <WagmiProvider config={wagmiAdapter.wagmiConfig}>
      <QueryClientProvider client={client}>
        {/* <ConfigProvider locale={ruRu}> */}
        <Routes />
        {/* </ConfigProvider> */}
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
